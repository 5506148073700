import React, { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import {
  Grid,
  Box,
  Container,
  TextField,
  Typography,
  Paper,
  Button,
  Checkbox
} from "@material-ui/core";

import admin from "../../services/admin";
import googleAnalytics from "../../services/googleAnalytics";

import LaunchScreen from "../LaunchScreen";
import CalculateScore from "../CalculateScore";
import { CodeBlock } from "react-code-blocks";

import moment from "moment-timezone";

import { withStyles } from "@material-ui/core/styles";
import EmptyState from "../EmptyState";

const styles = (theme) => ({
});

class AdminPage extends Component {

  constructor(props) {
    super(props);

    const pickId = localStorage.getItem('pickId') || ("LEAGUE-Player-Prop-" + moment().format("YYYY-MM-DD"));
    this.state = {
      ready: true,
      user: null,
      pick: null,
      userEmail: "",
      pickId: pickId,
      migrationName: "",
      migrationPage: 1,
      migrationEndPage: 2,
      migrationPerPage: 1000,
      migrationBackground: true,
      migrationData: null
    };
  }

  handleEmailChange = (event) => {
    const email = event.target.value;

    this.setState({
      userEmail: email,
    });
  };

  getUser = () => {
    const { userEmail } = this.state;
    admin.getUser(userEmail)
      .then((data) => {
        this.setState({
          user: data.user,
        });
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
  }

  handlePickIDChange = (event) => {
    const pickId = event.target.value;
    localStorage.setItem('pickId', pickId);

    this.setState({
      pickId: pickId,
    });
  };

  getPick = () => {
    const { pickId } = this.state;
    admin.getPick(pickId)
      .then((data) => {
        this.setState({
          pick: data.pick,
        });
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
  }

  handleMigrationNameChange = (event) => {
    const type = event.target.value;

    this.setState({
      migrationName: type,
    });
  }

  handleMigrationPageChange = (event) => {
    const page = Number.parseInt(event.target.value) || 1;

    this.setState({
      migrationPage: page,
    });
  }

  handleMigrationEndPageChange = (event) => {
    const page = Number.parseInt(event.target.value) || 1;

    this.setState({
      migrationEndPage: page,
    });
  }

  handleMigrationPerPageChange = (event) => {
    const perPage = Number.parseInt(event.target.value) || 1;

    this.setState({
      migrationPerPage: perPage,
    });
  }

  handleMigrationBackgroundChange = (event) => {
    if (!event) { return; }

    const isEnabled = event.target.checked;
    this.setState({
      migrationBackground: isEnabled,
    });
  };

  runMigration = () => {
    const { migrationName, migrationPage, migrationEndPage, migrationPerPage, migrationBackground } = this.state;
    admin.runMigration(migrationName, migrationPage, migrationEndPage, migrationPerPage, migrationBackground)
      .then((data) => {
        this.setState({
          migrationData: data,
        });
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
  }

  componentDidMount() {
    document.title = "Admin | HammerPicks";
    googleAnalytics.logPageView(document.title);
  }

  render() {
    const { openSnackbar } = this.props;
    const { ready, user, userEmail, pick, pickId, migrationName, migrationPage, migrationEndPage, migrationPerPage, migrationBackground, migrationData } = this.state;
    const admin = this.props.user;

    if (!admin || !admin.is_admin) {
      return (
        <EmptyState
          title="Unauthorized"
          description="You are not authorized to view this page."
        />
      );
    }

    return (
      <>
        {!ready && <LaunchScreen />}
        {ready &&
          <Container>
            <Grid container spacing={2} style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid item xs={12} sm={6} md={4}>
                <Box component={Paper} p={3}>
                  <Typography variant="h4">
                    User Lookup
                  </Typography>
                  <br />
                  <TextField
                    disabled={false}
                    fullWidth
                    inputProps={{
                      autoCapitalize: 'none',
                    }}
                    label="E-mail address"
                    placeholder="john@doe.com"
                    required
                    type="emailAddress"
                    value={userEmail}
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    onChange={this.handleEmailChange}
                  />
                  <Button
                    color="primary"
                    onClick={() => this.getUser()}
                  >
                    Submit
                  </Button>
                  <br />
                  {user &&
                    <CodeBlock
                      text={JSON.stringify(user).replace(/,/g, ",\n").replace("{", "").replace("}", "")}
                      showLineNumbers={false}
                      wrapLongLines={true}
                    />
                  }
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box component={Paper} p={3}>
                  <Typography variant="h4">
                    Pick Lookup
                  </Typography>
                  <br />
                  <TextField
                    autoComplete="none"
                    disabled={false}
                    fullWidth
                    inputProps={{
                      autoCapitalize: 'none',
                    }}
                    label="Pick ID"
                    placeholder="LEAGUE-Player-Prop-2023-01-01"
                    required
                    type="pickId"
                    value={pickId}
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    onChange={this.handlePickIDChange}
                  />
                  <Button
                    color="primary"
                    onClick={() => this.getPick()}
                  >
                    Submit
                  </Button>
                  <br />
                  {pick &&
                    <Box>
                      <CodeBlock
                        text={JSON.stringify(pick).replace(/,/g, ",\n").replace("{", "").replace("}", "")}
                        showLineNumbers={false}
                        wrapLongLines={true}
                      />
                    </Box>
                  }
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CalculateScore openSnackbar={openSnackbar} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box component={Paper} p={3}>
                  <Typography variant="h4">
                    Run Migration
                  </Typography>
                  <br />
                  <TextField
                    autoComplete="migrationName"
                    disabled={false}
                    fullWidth
                    inputProps={{
                      autoCapitalize: 'none',
                    }}
                    label="Migration Name"
                    placeholder="type"
                    required
                    type="text"
                    value={migrationName}
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    onChange={this.handleMigrationNameChange}
                  />
                  <TextField
                    autoComplete="migrationPage"
                    disabled={false}
                    inputProps={{
                      autoCapitalize: 'none',
                    }}
                    label="Migration Page"
                    placeholder="page"
                    required
                    type="number"
                    value={migrationPage}
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    onChange={this.handleMigrationPageChange}
                  />
                  <TextField
                    autoComplete="migrationEndPage"
                    disabled={false}
                    inputProps={{
                      autoCapitalize: 'none',
                    }}
                    label="Migration End Page"
                    placeholder="end page"
                    required
                    type="number"
                    value={migrationEndPage}
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    onChange={this.handleMigrationEndPageChange}
                  />
                  <TextField
                    autoComplete="migrationPerPage"
                    disabled={false}
                    inputProps={{
                      autoCapitalize: 'none',
                    }}
                    label="Migration Per Page"
                    placeholder="page"
                    required
                    type="number"
                    value={migrationPerPage}
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    onChange={this.handleMigrationPerPageChange}
                  />
                  <Checkbox
                    color="secondary"
                    defaultChecked={migrationBackground}
                    disabled={false}
                    variant="contained"
                    onClick={this.handleMigrationBackgroundChange}
                  />
                  <Button
                    color="primary"
                    onClick={() => this.runMigration()}
                  >
                    Submit
                  </Button>
                  <br />
                  {migrationData &&
                    <>
                      <Typography>
                        {JSON.stringify(migrationData)}
                      </Typography>
                    </>
                  }
                </Box>
              </Grid>
            </Grid >
          </Container >
        }
      </>
    );
  }
}

AdminPage.propTypes = {
  user: PropTypes.object,
  openSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(withRouter(AdminPage));
