import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Container,
} from "@material-ui/core";

import CheckoutTiers from "../CheckoutTiers";
import EmptyState from "../EmptyState";

class SubscribeSplashPage extends Component {

  render() {
    const {
      user,
      onCheckoutClicked,
      openSnackbar
    } = this.props;
    if (!user) {
      return (
        <EmptyState
          title="No User"
          description="Please sign up or sign in to continue."
        />
      );
    }
    return (
      <Container>
        <CheckoutTiers
          title="Subscribe to HammerPicks"
          description="Choose your subscription length. The longer the subscription, the more the discount. Cancel anytime."
          user={user}
          highlightedTiers={["week", "month", "6-month", "year"]}
          onCheckoutClicked={onCheckoutClicked}
          openSnackbar={openSnackbar}
        />
      </Container>
    );
  }
}

SubscribeSplashPage.propTypes = {
  user: PropTypes.object,
  onCheckoutClicked: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired
};

export default SubscribeSplashPage;
