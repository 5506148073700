import React, { Component } from "react";

import { withStyles } from '@material-ui/core/styles';

import PropTypes from "prop-types";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import HomePage from "../HomePage";
import StatsPage from "../StatsPage";
import NotFoundPage from "../NotFoundPage";
import ChangePasswordPage from "../ChangePasswordPage";
import CheckoutSuccessPage from "../CheckoutSuccessPage";
import PicksPage from "../PicksPage";
import ManagePaymentPage from "../ManagePaymentPage";
import ArticlePage from "../ArticlePage";
import SubscribeSplashPage from "../SubscribeSplashPage";
import SubscriptionUpgradePage from "../SubscriptionUpgradePage";
import SettingsPage from "../SettingsPage";
import PicksTutorialPage from "../PicksTutorialPage";
import AboutPage from "../AboutPage";
import AppRedirectPage from "../AppRedirectPage";
import AdminPage from "../AdminPage";
import PromoPage from "../PromoPage/PromoPage";
import EmailSettingsPage from "../EmailSettingsPage";

const styles = (theme) => ({
  container: {
    "display": "flex",
    "min-height": "100vh",
    "flex-direction": "column"
  },
  content: {
    "flex": 1
  }
});

class Router extends Component {

  render() {

    const { user, leagues, sportsbooks, propsites, bar, footer, classes } = this.props;

    const { openSnackbar, onCheckoutClicked, onSignUpClicked, onCustomerPortalClicked, onFAQClicked, onPicksTutorialClicked } = this.props;

    return (
      <div className={classes.container}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          {bar}
          <div className={classes.content}>
            <Switch>
              <Route path="/" exact>
                <HomePage
                  user={user}
                  propsites={propsites}
                  openSnackbar={openSnackbar}
                  onCheckoutClicked={onCheckoutClicked}
                  onSignUpClicked={onSignUpClicked}
                  onCustomerPortalClicked={onCustomerPortalClicked}
                  onFAQClicked={onFAQClicked}
                />
              </Route>
              <Route path="/picks" exact render={() => (
                <PicksPage
                  user={user}
                  propsites={propsites}
                  openSnackbar={openSnackbar}
                  onPicksTutorialClicked={onPicksTutorialClicked}
                />
              )} />
              <Route path="/success" exact>
                <CheckoutSuccessPage user={user} openSnackbar={openSnackbar} />
              </Route>
              <Route path="/change-password" exact>
                <ChangePasswordPage user={user} openSnackbar={openSnackbar} />
              </Route>
              <Route path="/picks-tutorial" exact>
                <PicksTutorialPage
                  openSnackbar={openSnackbar}
                />
              </Route>
              <Route path="/about" exact>
                <AboutPage
                  propsites={propsites}
                  openSnackbar={openSnackbar}
                />
              </Route>
              <Route path="/manage-payment" exact>
                <ManagePaymentPage
                  user={user}
                  onCustomerPortalClicked={onCustomerPortalClicked}
                  openSnackbar={openSnackbar}
                />
              </Route>
              <Route path="/settings" exact>
                <SettingsPage
                  user={user}
                  leagues={leagues}
                  sportsbooks={sportsbooks}
                  propsites={propsites}
                  openSnackbar={openSnackbar}
                />
              </Route>
              <Route path="/stats" exact>
                <StatsPage
                  user={user}
                  leagues={leagues}
                  sportsbooks={sportsbooks}
                  propsites={propsites}
                  openSnackbar={openSnackbar}
                />
              </Route>
              <Route path="/subscribe" exact>
                <SubscribeSplashPage
                  user={user}
                  onCheckoutClicked={onCheckoutClicked}
                  openSnackbar={openSnackbar}
                />
              </Route>
              <Route path="/upgrade" exact>
                <SubscriptionUpgradePage
                  user={user}
                  openSnackbar={openSnackbar}
                />
              </Route>
              <Route path="/promo" exact>
                <PromoPage
                  user={user}
                  openSnackbar={openSnackbar}
                />
              </Route>
              <Route path="/email-settings" exact>
                <EmailSettingsPage
                  user={user}
                  openSnackbar={openSnackbar}
                />
              </Route>
              <Route path="/app-redirect" exact>
                <AppRedirectPage
                  openSnackbar={openSnackbar}
                />
              </Route>
              <Route path="/admin" exact>
                <AdminPage
                  user={user}
                  openSnackbar={openSnackbar}
                />
              </Route>
              <Route path="/article/:id">
                <ArticlePage
                  user={user}
                  openSnackbar={openSnackbar}
                />
              </Route>
              <Route>
                <NotFoundPage />
              </Route>
            </Switch>
          </div>
          {footer}
        </BrowserRouter >
      </div>
    );
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  leagues: PropTypes.array.isRequired,
  bar: PropTypes.element,
  footer: PropTypes.element,
  sportsbooks: PropTypes.array.isRequired,
  propsites: PropTypes.array.isRequired,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
  onCheckoutClicked: PropTypes.func.isRequired,
  onSignUpClicked: PropTypes.func.isRequired,
  onCustomerPortalClicked: PropTypes.func.isRequired,
  onFAQClicked: PropTypes.func.isRequired,
  onPicksTutorialClicked: PropTypes.func.isRequired,
};

export default withStyles(styles)(Router);
