import React, { Component } from "react";

import readingTime from "reading-time";

import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from "@material-ui/core/styles";

import { CssBaseline, Button, Snackbar } from "@material-ui/core";

import authentication from "../../services/authentication";
import subscription from "../../services/subscription";
import appearance from "../../services/appearance";

import ErrorBoundary from "../ErrorBoundary";
import LaunchScreen from "../LaunchScreen";
import Bar from "../Bar";
import Router from "../Router";
import DialogHost from "../DialogHost";
import Footer from "../Footer";

import { loadStripe } from "@stripe/stripe-js";
import googleAnalytics from "../../services/googleAnalytics";
import { firestore } from '../../firebase';

const initialState = {
  ready: false,
  performingAction: false,
  theme: appearance.defaultTheme,
  user: null,
  stripe: null,
  articles: [],
  retentionPromoCode: "HAMMERTIME", // TODO

  aboutDialog: {
    open: false,
    title: "About"
  },

  faqDialog: {
    open: false,
    title: "FAQ"
  },

  picksTutorialDialog: {
    open: false,
    title: "Picks Tutorial"
  },

  signUpDialog: {
    open: false,
    title: "Sign Up"
  },

  signInDialog: {
    open: false,
    title: "Sign In"
  },

  settingsDialog: {
    open: false,
    title: "Settings"
  },

  retentionPromoDialog: {
    open: false,
    title: "Retention Promo"
  },

  deleteAccountDialog: {
    open: false,
    title: "Delete Account"
  },

  signOutDialog: {
    open: false,
    title: "Sign Out"
  },

  snackbar: {
    autoHideDuration: 0,
    message: "",
    open: false,
  },
};

const styles = (theme) => ({
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  resetState = (callback) => {
    this.setState(
      {
        ready: true,
        theme: appearance.defaultTheme,
        user: null,
      },
      callback
    );
  };

  openDialog = (dialogId, callback) => {
    const dialog = this.state[dialogId];

    if (!dialog || dialog.open === undefined || dialog.open === null || dialog.open === true) {
      return false;
    }

    const title = dialog.title;
    if (title) {
      this.setState({
        previousTitle: document.title
      })
      document.title = `${title} | HammerPicks`;
      googleAnalytics.logModalView(title);
    }

    dialog.open = true;

    this.setState({ dialog }, callback);
    return true;
  };

  closeDialog = (dialogId, callback) => {
    const dialog = this.state[dialogId];

    if (!dialog || dialog.open === undefined || dialog.open === null || dialog.open === false) {
      return;
    }

    const previousTitle = this.state.previousTitle;
    if (previousTitle) {
      document.title = previousTitle;
    }

    dialog.open = false;

    this.setState({ dialog }, callback);
  };

  closeAllDialogs = (callback) => {
    this.setState(
      {
        aboutDialog: {
          open: false,
        },

        faqDialog: {
          open: false,
        },

        picksTutorialDialog: {
          open: false,
        },

        signUpDialog: {
          open: false,
        },

        signInDialog: {
          open: false,
        },

        settingsDialog: {
          open: false,
        },

        retentionPromoDialog: {
          open: false
        },

        deleteAccountDialog: {
          open: false,
        },

        signOutDialog: {
          open: false,
        },
      },
      callback
    );
  };

  deleteAccount = () => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        authentication
          .deleteAccount()
          .then(() => {
            this.closeAllDialogs(() => {
              this.openSnackbar("Deleted account");
            });
          })
          .catch((error) => {
            this.openSnackbar(error);
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      }
    );
  };

  signOut = () => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        authentication
          .signOut()
          .then(() => {
            this.closeAllDialogs(() => {
              this.openSnackbar("Signed out");
            });
          })
          .catch((error) => {
            this.openSnackbar(error);
          })
          .finally(() => {
            this.setState({
              performingAction: false,
            });
          });
      }
    );
  };

  openSnackbar = (obj, autoHideDuration = 2, callback) => {
    let message = "";
    if (typeof obj === 'string' || obj instanceof String) {
      message = obj;
    } else if (typeof obj === 'error' || obj instanceof Error) {
      message = obj.message;
      if (obj.response && (typeof obj.response.data === 'string' || obj.response.data instanceof String)) {
        message = obj.response.data;
      }
    }
    this.setState(
      {
        snackbar: {
          autoHideDuration: readingTime(message).time * autoHideDuration,
          message,
          open: true,
        },
      },
      () => {
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );
  };

  closeSnackbar = (clearMessage = false) => {
    const { snackbar } = this.state;

    this.setState({
      snackbar: {
        message: clearMessage ? "" : snackbar.message,
        open: false,
      },
    });
  };

  openHashes = () => {
    if (window.location.hash === "#sign-in") {
      if (this.openDialog("signInDialog")) {
        window.location.hash = "";
      }
    } else if (window.location.hash === "#settings") {
      if (this.openDialog("settingsDialog")) {
        window.location.hash = "";
      }
    } else if (window.location.hash === "#faq") {
      if (this.openDialog("faqDialog")) {
        window.location.hash = "";
      }
    }
  }

  render() {
    const {
      ready,
      performingAction,
      theme,
      user,
      articles,
    } = this.state;

    const {
      aboutDialog,
      faqDialog,
      picksTutorialDialog,
      signUpDialog,
      signInDialog,
      settingsDialog,
      retentionPromoDialog,
      deleteAccountDialog,
      signOutDialog,
    } = this.state;

    const { snackbar, retentionPromoCode } = this.state;

    const leagues = process.env.REACT_APP_LEAGUES.split(",")
    const sportsbooks = process.env.REACT_APP_SPORTSBOOKS.split(",")
    const propsites = process.env.REACT_APP_PROPSITES.split(",")

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />

        <ErrorBoundary>
          {!ready && <LaunchScreen />}

          {ready && (
            <>
              <Router
                user={user}
                leagues={leagues}
                sportsbooks={sportsbooks}
                propsites={propsites}
                bar={
                  <Bar
                    performingAction={performingAction}
                    theme={theme}
                    user={user}
                    onSignUpClick={() => this.openDialog("signUpDialog")}
                    onSignInClick={() => this.openDialog("signInDialog")}
                    onAboutClick={() => this.openDialog("aboutDialog")}
                    onFAQClick={() => this.openDialog("faqDialog")}
                    onPicksTutorialClick={() => this.openDialog("picksTutorialDialog")}
                    onSettingsClick={() => this.openDialog("settingsDialog")}
                    onSignOutClick={() => this.openDialog("signOutDialog")}
                  />
                }
                openSnackbar={this.openSnackbar}
                onCheckoutClicked={(tier) => this.toCheckout(tier)}
                onCustomerPortalClicked={(shouldPromptRetention) => this.toCustomerPortal(shouldPromptRetention)}
                onSignUpClicked={() => this.openDialog("signUpDialog")}
                onFAQClicked={() => this.openDialog("faqDialog")}
                onPicksTutorialClicked={() => this.openDialog("picksTutorialDialog")}
                footer={
                  <Footer articles={articles}></Footer>
                }
              />

              <DialogHost
                performingAction={performingAction}
                theme={theme}
                user={user}
                leagues={leagues}
                sportsbooks={sportsbooks}
                propsites={propsites}
                openSnackbar={this.openSnackbar}
                dialogs={{
                  aboutDialog: {
                    dialogProps: {
                      open: aboutDialog.open,

                      onClose: () => this.closeDialog("aboutDialog"),
                    },
                  },

                  faqDialog: {
                    dialogProps: {
                      open: faqDialog.open,

                      onClose: () => this.closeDialog("faqDialog"),
                    },
                  },

                  picksTutorialDialog: {
                    dialogProps: {
                      open: picksTutorialDialog.open,

                      onClose: () => this.closeDialog("picksTutorialDialog"),
                    },
                  },

                  signUpDialog: {
                    dialogProps: {
                      open: signUpDialog.open,

                      onClose: (callback) => {
                        this.closeDialog("signUpDialog");

                        if (callback && typeof callback === "function") {
                          callback();
                        }
                      },
                    },
                  },

                  signInDialog: {
                    dialogProps: {
                      open: signInDialog.open,

                      onClose: (callback) => {
                        this.closeDialog("signInDialog");

                        if (callback && typeof callback === "function") {
                          callback();
                        }
                      },
                    },
                  },

                  settingsDialog: {
                    dialogProps: {
                      open: settingsDialog.open,

                      onClose: () => this.closeDialog("settingsDialog"),
                    },

                    props: {
                      onCheckoutClicked: (tier) => this.toCheckout(tier),
                      onCustomerPortalClicked: (shouldPromptRetention) => this.toCustomerPortal(shouldPromptRetention),
                    },
                  },

                  retentionPromoDialog: {
                    dialogProps: {
                      open: retentionPromoDialog.open,

                      onClose: () => this.closeDialog("retentionPromoDialog"),
                    },

                    props: {
                      promoCode: retentionPromoCode,
                      onCustomerPortalClicked: () => this.toCustomerPortal(false),
                    },
                  },

                  deleteAccountDialog: {
                    dialogProps: {
                      open: deleteAccountDialog.open,

                      onClose: () => this.closeDialog("deleteAccountDialog"),
                    },

                    props: {
                      deleteAccount: this.deleteAccount,
                    },
                  },

                  signOutDialog: {
                    dialogProps: {
                      open: signOutDialog.open,

                      onClose: () => this.closeDialog("signOutDialog"),
                    },

                    props: {
                      title: "Are you sure?",
                      contentText:
                        "If you sign out you will be unable to view to picks or manage your account.",
                      dismissiveAction: (
                        <Button
                          color="primary"
                          onClick={() => this.closeDialog("signOutDialog")}
                        >
                          Cancel
                        </Button>
                      ),
                      confirmingAction: (
                        <Button
                          color="primary"
                          disabled={performingAction}
                          variant="contained"
                          onClick={this.signOut}
                        >
                          Sign Out
                        </Button>
                      ),
                    },
                  },
                }}
              />

              <Snackbar
                autoHideDuration={snackbar.autoHideDuration}
                message={snackbar.message}
                open={snackbar.open}
                onClose={this.closeSnackbar}
              />
            </>
          )}
        </ErrorBoundary>
      </MuiThemeProvider>
    );
  }


  async toCheckout(tier) {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
    subscription.createCheckoutSession(tier)
      .then((sessionId) => {
        try {
          stripe.redirectToCheckout({
            sessionId: sessionId
          })
        } catch (error) {
          this.openSnackbar(error);
        }
      })
      .catch((error) => {
        this.openSnackbar(error);
      })
  }

  toCustomerPortal(shouldPromptRetention) {
    if (!shouldPromptRetention) {
      this.actuallyToCustomerPortal();
    } else {
      const { user } = this.state;
      const { retentionPromoCode } = this.state;
      const appliedPromoAt = (user && user.applied_promo_at) || {};
      if (!retentionPromoCode || appliedPromoAt[retentionPromoCode] !== undefined) {
        this.actuallyToCustomerPortal();
      } else {
        this.openDialog("retentionPromoDialog");
      }
    }
  }

  actuallyToCustomerPortal() {
    subscription.createCustomerPortalSession()
      .then((sessionURL) => {
        window.location.replace(sessionURL);
      })
      .catch((error) => {
        this.openSnackbar(error);
      })
  }

  componentDidUpdate() {
    this.openHashes();
  }

  componentDidMount() {
    this.openHashes();

    authentication.configureInterceptors();

    authentication.didChangeAuth = (user) => {
      this.setState({
        ready: true,
        user: user
      });
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const autoShowCheckout = params.disableAutoShowCheckout !== "true";
      const tier = window.parent.location.hash.substring(1);
      if (user && user.is_email_verified && !user.subscription_ends_at && autoShowCheckout && tier && tier.length > 0) {
        this.toCheckout(tier);
      }
    }
    authentication.getCurrentUser();

    this.articlesSnapshotListener = firestore.collection("articles").orderBy("publishedAt", "desc").onSnapshot((snapshot) => {
      if (!snapshot || snapshot.isEmpty) {
        return;
      }

      const data = snapshot.docs.map(doc => {
        return {
          id: doc.id,
          data: doc.data()
        }
      })

      this.setState({
        articles: data,
      });
    }, (error) => {
      console.log(error);
    });
  }

  componentWillUnmount() {
    if (this.articlesSnapshotListener) {
      this.articlesSnapshotListener();
    }
  }
}

export default withStyles(styles)(App);
