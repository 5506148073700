import React, { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import {
  Box,
  TextField,
  Typography,
  Paper,
  Button,
} from "@material-ui/core";

import admin from "../../services/admin";

import LaunchScreen from "../LaunchScreen";
import { CodeBlock } from "react-code-blocks";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
});

class CalculateScore extends Component {

  constructor(props) {
    super(props);

    let score = {
      psLineValue: 27.5,
      sbLineValue: 22.5,
      sbOver: -140,
      sbUnder: +110,
      averageLine: 25,
      maxLineRatio: 0.5,
      deboostedLine: 0,
      lineWeight: 0.25,
      oddsWeight: 0.75
    };

    const scoreString = localStorage.getItem('score');
    if (scoreString && scoreString.length > 0) {
      score = JSON.parse(scoreString);
    }

    this.state = {
      ready: true,
      score: score,
      result: null
    };
  }

  handleValueChange = (event, key) => {
    const value = event.target.value;

    let score = this.state.score;
    score[key] = value;
    localStorage.setItem('score', JSON.stringify(score));

    this.setState({
      score: score
    });
  };

  calculateScore = () => {
    admin.calculateScore(this.state.score)
      .then((data) => {
        this.setState({
          result: data,
        });
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
  };

  render() {
    const { ready, result } = this.state;

    const keys = [
      "psLineValue",
      "sbLineValue",
      "sbOver",
      "sbUnder",
      "averageLine",
      "maxLineRatio",
      "deboostedLine",
      "lineWeight",
      "oddsWeight"
    ];

    return (
      <>
        {!ready && <LaunchScreen />}
        {ready &&
          <Box component={Paper} p={3}>
            <Typography variant="h4">
              Calculate Score
            </Typography>
            <br />
            {keys.map(key => {
              return (
                <TextField
                  autoComplete={key}
                  disabled={false}
                  inputProps={{
                    autoCapitalize: 'none',
                  }}
                  label={key}
                  placeholder={key}
                  required
                  type="number"
                  value={this.state.score[key]}
                  variant="outlined"
                  InputLabelProps={{ required: false }}
                  onChange={(e) => this.handleValueChange(e, key)}
                />
              );
            })}
            <Button
              color="primary"
              onClick={() => this.calculateScore()}
            >
              Submit
            </Button>
            <br />
            {result &&
              <CodeBlock
                text={JSON.stringify(result.score).replace(/,/g, ",\n").replace("{", "").replace("}", "")}
                showLineNumbers={false}
                wrapLongLines={true}
              />
            }
          </Box>
        }
      </>
    );
  }
}

CalculateScore.propTypes = {
  openSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(withRouter(CalculateScore));
