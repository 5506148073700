import React, { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import withWidth from '@material-ui/core/withWidth';

import {
  Grid,
  Box,
  Container,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  ButtonGroup,
  CircularProgress
} from "@material-ui/core";

import googleAnalytics from "../../services/googleAnalytics";
import stats from "../../services/stats";
import social from "../../services/social";

import { scaleBand } from "@devexpress/dx-chart-core";
import { ArgumentScale, Stack } from "@devexpress/dx-react-chart";

import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  BarSeries,
  Legend
} from '@devexpress/dx-react-chart-material-ui';

import LaunchScreen from "../LaunchScreen";
import NotFoundPage from "../NotFoundPage";
import MultiSelectField from "../MultiSelectField";

import moment from "moment";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  tableHeader: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[300],
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "10vh",
    width: "100%"
  }
});

const Root = props => (
  <Legend.Root {...props} sx={{ display: 'flex', margin: 'auto', flexDirection: 'row' }} />
);
const Label = props => (
  <Legend.Label {...props} sx={{ whiteSpace: 'nowrap' }} />
);

class StatsPage extends Component {

  constructor(props) {
    super(props);

    const isSmallScreen = props.width === "xs" || props.width === "sm";
    const picksDays = this.generatePicksDays();
    this.state = {
      ready: true,
      loadingUsers: false,
      dateFrom: picksDays[5].dateFrom,
      dateTo: picksDays[5].dateTo,
      picksDaysIndex: 5,
      picksDays: picksDays,
      userDaysAgo: isSmallScreen ? 7 : 14,
      days: [],
      totals: {},
      sources: {},
      recentSignups: [],
      recentCancellations: [],
      outcomeData: {},
      recordData: {},
      publicData: {},
      recentPublicPicks: [],
      meta: {},
      selectedLeagues: null,
      selectedSportsbooks: null,
      selectedPropsites: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      const { user } = nextProps;
      if (!user || !user.is_admin) {
        return;
      }
      this.getStatsPicks();
      this.getStatsUsers();
    }
  }

  generateDays(data) {
    const days = data.days.map(d => {
      let date = moment.utc(d.day);
      return {
        "date": date.format("M/D"),
        "activeFree": d.active_free_subscribers.length,
        "activePaying": d.active_paying_subscribers.length,
        "cancelled": d.cancelled_subscribers.length,
        "inactive": d.inactive_subscribers.length,
        "non": d.non_subscribers.length,
        "activeCancelled": d.active_cancelled_subscribers.length
      }
    });
    return days;
  };


  generatePicksDays() {
    const firstDayOfLastMonth = moment().subtract(1, 'month').startOf('month');
    const lastDayOfLastMonth = moment().subtract(1, 'month').endOf('month');
    return [
      {
        title: "Yesterday",
        dateFrom: moment().subtract(1, 'days').format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
      },
      {
        title: "Last Week",
        dateFrom: moment().subtract(7, 'days').format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
      },
      {
        title: "Last Two Weeks",
        dateFrom: moment().subtract(14, 'days').format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
      },
      {
        title: "Last 28 days",
        dateFrom: moment().subtract(28, 'days').format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
      },
      {
        title: firstDayOfLastMonth.format("MMMM"),
        dateFrom: firstDayOfLastMonth.format("YYYY-MM-DD"),
        dateTo: lastDayOfLastMonth.format("YYYY-MM-DD"),
      },
      {
        title: moment().format("MMMM"),
        dateFrom: moment().startOf('month').format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
      },
      {
        title: moment().format("YYYY"),
        dateFrom: moment().startOf('year').format("YYYY-MM-DD"),
        dateTo: moment().format("YYYY-MM-DD"),
      }
    ];
  }

  onSetCancellationReason(email, platform) {
    const reason = window.prompt(`Please input the reason for ${email} [${platform}]:`);
    if (reason && reason.length) {
      stats.userUpdateForce(email, reason)
        .then((data) => {
          this.getStatsUsers();
        })
        .catch((error) => {
          this.props.openSnackbar(error);
        })
    } else {
      window.open(`https://dashboard.stripe.com/search?query=${email}`, '_blank');
    }
  }

  onChangePicksFromToDates = (picksDay, i) => {
    this.setState({
      dateFrom: picksDay.dateFrom,
      dateTo: picksDay.dateTo || moment().format("YYYY-MM-DD"),
      picksDaysIndex: i
    }, () => {
      this.getStatsPicks();
    });
  }

  onChangeUserDaysAgo = (daysAgo) => {
    this.setState({
      userDaysAgo: daysAgo
    }, () => {
      this.getStatsUsers();
    });
  }

  onUpdateLeaguesSetting = (leagues) => {
    this.setState({
      selectedLeagues: leagues
    }, () => {
      this.getStatsPicks();
    });
  };

  onUpdateSportsbooksSetting = (sportsbooks) => {
    this.setState({
      selectedSportsbooks: sportsbooks
    }, () => {
      this.getStatsPicks();
    });
  };

  onUpdatePropsitesSetting = (propsites) => {
    this.setState({
      selectedPropsites: propsites
    }, () => {
      this.getStatsPicks();
    });
  };

  getStatsUsers = () => {
    const {
      userDaysAgo,
    } = this.state;
    this.setState({
      loadingUsers: true,
    });
    stats.getStatsUsers(userDaysAgo)
      .then((data) => {
        const days = this.generateDays(data);
        let { meta } = this.state;
        meta.userDaysAgo = data.meta.user_days_ago;
        this.setState({
          days: days,
          totals: {
            all_time: data.total_all_time,
            days: data.total_days
          },
          sources: data.sources,
          recentSignups: data.recent_signups,
          recentCancellations: data.recent_cancellations,
          tiers: data.tiers,
          meta: meta,
          loadingUsers: false,
        });
      })
      .catch((error) => {
        this.props.openSnackbar(error);
        this.setState({
          loadingUsers: false,
        });
      });
  }

  getStatsPicks = () => {
    const {
      dateFrom,
      dateTo,
      selectedLeagues,
      selectedSportsbooks,
      selectedPropsites
    } = this.state;
    ["last", "optimal", "public", "recent_public_picks", "outcomes"].forEach(type => {
      stats.getStatsPicks(type, dateFrom, dateTo, selectedLeagues, selectedSportsbooks, selectedPropsites)
        .then((data) => {
          switch (type) {
            case "last":
            case "optimal":
              let { recordData } = this.state;
              recordData[type] = data.data;
              this.setState({
                recordData: recordData,
              });
              break;
            case "public":
              let { meta } = this.state;
              meta.publicLastFrom = data.meta.public_last_from;
              this.setState({
                publicData: data.data,
                meta: meta,
              });
              break;
            case "recent_public_picks":
              this.setState({
                recentPublicPicks: data.data,
              });
              break;
            case "outcomes":
              this.setState({
                outcomeData: data.data,
              });
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          this.props.openSnackbar(error);
        });
    });
  }

  componentDidMount() {
    document.title = "Stats | HammerPicks";
    googleAnalytics.logPageView(document.title);

    const { user } = this.props;
    if (!user || !user.is_admin) {
      return;
    }
    this.getStatsPicks();
    this.getStatsUsers();
  }

  copyPublicPickWinText = (pick) => {
    let message = `CASH ${pick.name} ${pick.public_choice === "OVER" ? "o" : "u"}${pick.public_ps_line_value} ${pick.prop} ✅💰🔨`;
    message += `\n\nSubscribe at 👉 HammerPicks.com`;
    message += `\n\n${social.winHashtags(pick.public_propsite)}`;
    navigator.clipboard.writeText(message);
  }

  daysAgoToString(days) {
    switch (days) {
      case 1:
        return "Yesterday";
      case 7:
        return "Last Week";
      case 14:
        return "Last Two Weeks";
      default:
        return `Last ${days} days`;
    }
  }

  render() {
    const { user, leagues, sportsbooks, propsites, classes } = this.props;
    const { picksDays, picksDaysIndex, days, totals, recentCancellations, recentSignups, outcomeData, recordData, publicData, recentPublicPicks, tiers, meta, selectedLeagues, selectedSportsbooks, selectedPropsites, loadingUsers, ready } = this.state;

    const tiersKeys = ["week", "month", "6month", "year", "lifetime"];

    if (!user || !user.is_admin) {
      return (
        <NotFoundPage />
      )
    }
    return (
      <>
        {!ready && <LaunchScreen />}
        {ready &&
          <Grid container spacing={2} style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Container key={"record-container"}>
              <Grid container spacing={2} style={{ marginTop: "20px", marginBottom: "20px" }}>
                <Grid
                  container
                  spacing={4}
                >
                  <Container>
                    <Grid item xs={12} key={"record-picks"}>
                      <Box px={{ xs: 1, sm: 0 }} key={"record-picks-1"}>
                        <ButtonGroup color="secondary" aria-label="secondary button group">
                          {picksDays.map((picksDay, i) => {
                            return (
                              <Button
                                variant={picksDaysIndex === i ? "contained" : "outlined"}
                                onClick={() => this.onChangePicksFromToDates(picksDay, i)}
                                key={`${i}-record-button-days`}>
                                {picksDay.title}
                              </Button>
                            )
                          })}
                        </ButtonGroup>
                      </Box>
                    </Grid>
                    <Grid item xs={12} key={"record-picks-league"}>
                      <Box px={{ xs: 1, sm: 0 }} key={"record-picks-league-1"}>
                        <MultiSelectField
                          key={"league"}
                          options={leagues}
                          selected={selectedLeagues}
                          selectAllIfNone={false}
                          name={"Leagues"}
                          onClose={this.onUpdateLeaguesSetting}
                        >
                        </MultiSelectField>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <MultiSelectField
                          key={"sportsbook"}
                          options={sportsbooks}
                          selected={selectedSportsbooks}
                          selectAllIfNone={false}
                          name={"Sportsbooks"}
                          onClose={this.onUpdateSportsbooksSetting}
                        >
                        </MultiSelectField>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <MultiSelectField
                          key={"propsite"}
                          options={propsites}
                          selected={selectedPropsites}
                          selectAllIfNone={false}
                          name={"Propsites"}
                          onClose={this.onUpdatePropsitesSetting}
                        >
                        </MultiSelectField>
                      </Box>
                    </Grid>
                  </Container>
                </Grid>
                {["last", "optimal"].map(recordType => {
                  return (
                    <Grid item xs={12} md={3} key={recordType}>
                      <Box>
                        <TableContainer component={Paper}>
                          <Table aria-label={`Record ${recordType}`}>
                            <TableHead className={classes.tableHeader}>
                              <TableRow>
                                <TableCell colSpan={3} align="center">
                                  <Typography variant="h5">
                                    {`Record (${recordType}) ${recordType === "last" ? "✅" : "✔️"}`}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {["🔨🔨🔨", "🔨🔨", "🔨", "All"].map(recordKey => {
                                const hasValue = recordData && recordData[recordType] && recordData[recordType][recordKey];
                                if (!hasValue) {
                                  return (
                                    <TableRow key={`${recordType}-${recordKey}`}>
                                      <Typography variant="body1">
                                        Loading...
                                      </Typography>
                                    </TableRow>
                                  )
                                }
                                const wins = recordData[recordType][recordKey]["win"] || 0;
                                const losses = recordData[recordType][recordKey]["loss"] || 0;
                                const pushes = recordData[recordType][recordKey]["push"] || 0;
                                const percentage = (100 * (wins / (wins + losses))).toFixed(1);
                                return (
                                  <TableRow key={`${recordType}-${recordKey}`}>
                                    <TableCell component="th" scope="row" align="right">
                                      {recordKey}
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="body1">
                                        {`${percentage}%`}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="body1">
                                        {`${wins}-${losses}-${pushes}`}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                  )
                })}
                <Grid item xs={12} md={3} key={"record-public"}>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table aria-label={`Record Public`}>
                        <TableHead className={classes.tableHeader}>
                          <TableRow>
                            <TableCell colSpan={3} align="center">
                              <Typography variant="h5">
                                {`Record (public) 🌎`}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {["recent"].map(recordType => {
                            const key = `public_${recordType}`;
                            const hasValue = publicData && publicData[key] && publicData[key];
                            if (!hasValue) {
                              return (
                                <TableRow key={key}>
                                  <Typography variant="body1">
                                    Loading...
                                  </Typography>
                                </TableRow>
                              )
                            }
                            const wins = publicData[key]["win"] || 0;
                            const losses = publicData[key]["loss"] || 0;
                            const pushes = publicData[key]["push"] || 0;
                            const percentage = (100 * (wins / (wins + losses))).toFixed(1);
                            return (
                              <TableRow key={key}>
                                <TableCell component="th" scope="row" align="right">
                                  <Typography variant="body1">
                                    {`${picksDays[picksDaysIndex].dateFrom} - ${picksDays[picksDaysIndex].dateTo}`}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body1">
                                    {`${percentage}%`}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body1">
                                    {`${wins}-${losses}-${pushes}`}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                          <TableRow key={`streak-public`}>
                            <TableCell component="th" scope="row" align="right">
                              <Typography variant="body1">
                                Streak
                              </Typography>
                            </TableCell>
                            <TableCell colSpan={2}>
                              <Typography variant="body1">
                                {publicData && publicData["public_streak"] && publicData["public_streak"].length} win(s) in a row
                              </Typography>
                            </TableCell>
                          </TableRow>
                          {["last"].map(recordType => {
                            const key = `public_${recordType}`;
                            const hasValue = publicData && publicData[key] && publicData[key];
                            if (!hasValue) {
                              return (
                                <TableRow key={key}>
                                  <Typography variant="body1">
                                    Loading...
                                  </Typography>
                                </TableRow>
                              )
                            }
                            const wins = publicData[key]["win"] || 0;
                            const losses = publicData[key]["loss"] || 0;
                            const pushes = publicData[key]["push"] || 0;
                            const percentage = (100 * (wins / (wins + losses))).toFixed(1);
                            return (
                              <TableRow key={key}>
                                <TableCell component="th" scope="row" align="right">
                                  <Typography variant="body1">
                                    Since {moment(meta.publicLastFrom).format("M/D")}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body1">
                                    {`${percentage}%`}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body1">
                                    {`${wins}-${losses}-${pushes}`}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} key={"recent-public-picks"}>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table aria-label={"recent-public-picks"}>
                        <TableHead className={classes.tableHeader}>
                          <TableRow>
                            <TableCell colSpan={3} align="center">
                              <Typography variant="h5">
                                {`Recent Public Picks 🚀`}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {recentPublicPicks.map(pick => {
                            return (
                              <TableRow key={pick.id}>
                                <TableCell component="th" scope="row" align="right">
                                  <Typography variant="body2">
                                    {pick.name}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    {pick.public_choice === "OVER" ? "o" : "u"}{pick.public_ps_line_value} {pick.prop}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    {pick.public_outcome &&
                                      {
                                        "WIN": "✅",
                                        "LOSS": "❌",
                                        "PUSH": "✖️"
                                      }[pick.public_outcome]
                                    }
                                    {!pick.public_outcome &&
                                      <Button size="small" variant="outlined" onClick={() => this.copyPublicPickWinText(pick)}>
                                        🚀
                                      </Button>
                                    }
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} key={"league-outcomes"}>
                <Box>
                  <TableContainer component={Paper}>
                    <Table aria-label={"League Outcomes"}>
                      <TableHead className={classes.tableHeader}>
                        <TableRow>
                          <TableCell colSpan={leagues.length} align="center" key={"outcomes-title"}>
                            <Typography variant="body1">
                              Outcomes
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {leagues.map(key => {
                            return (
                              <TableCell align="center" key={`outcomes-${key}`}>
                                <Typography variant="body1">
                                  {key}
                                </Typography>
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={`league-outcomes`}>
                          {leagues.map(leagueKey => {
                            const hasValue = outcomeData && outcomeData[leagueKey];
                            if (!hasValue) {
                              return (
                                <TableCell key={`outcomes-loading-${leagueKey}`}>
                                  <Typography variant="body1">
                                    Loading...
                                  </Typography>
                                </TableCell>
                              )
                            }
                            const nullOutcomes = (outcomeData[leagueKey]["None"]) || 0;
                            const outcomes = (outcomeData[leagueKey]["not None"]) || 0;
                            const percentage = (100 * (outcomes / (outcomes + nullOutcomes))).toFixed(1);
                            return (
                              <TableCell align="center" key={`outcomes-percentage-${leagueKey}`}>
                                <Typography variant="body1">
                                  {`${percentage}%`}
                                </Typography>
                                <Typography variant="body1" key={`outcomes-record-${leagueKey}`}>
                                  {`${outcomes}-${nullOutcomes}`}
                                </Typography>
                              </TableCell>
                            )
                          })
                          }
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Container>
            {loadingUsers &&
              <div className={classes.center}>
                <CircularProgress />
              </div>}
            {!loadingUsers &&
              <Grid item xs={12} key={"user-graph"}>
                <Box>
                  <Paper>
                    <Chart data={days}>
                      <ArgumentScale factory={scaleBand} />
                      <ArgumentAxis />
                      <ValueAxis showLabels={true} />
                      <BarSeries
                        valueField="activeFree"
                        argumentField="date"
                        name="Free Trials"
                      />
                      <BarSeries
                        valueField="cancelled"
                        argumentField="date"
                        name="Cancellations"
                      />
                      <BarSeries
                        valueField="activePaying"
                        argumentField="date"
                        name="Active Subscribers"
                      />
                      <BarSeries
                        valueField="non"
                        argumentField="date"
                        name="Non-Subscribers"
                      />
                      <BarSeries
                        valueField="inactive"
                        argumentField="date"
                        name="Re-trying"
                      />
                      <BarSeries
                        valueField="activeCancelled"
                        argumentField="date"
                        name="Active Cancellations"
                      />
                      <Stack
                        stacks={[
                          { series: ["Active Subscribers", "Cancellations", "Free Trials", "Non-Subscribers", "Re-trying", "Active Cancellations"] },
                        ]}
                      />
                      <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
                    </Chart>
                  </Paper>
                </Box>
              </Grid>}
            <Container key={"user-container"}>
              <Grid container spacing={2} style={{ marginTop: "20px", marginBottom: "20px" }}>
                <Grid item xs={12} key={"User"}>
                  <Box>
                    <ButtonGroup color="secondary" aria-label="secondary button group">
                      {[7, 14, 28, 90, 180, 365].map(days => {
                        return (
                          <Button
                            variant={meta.userDaysAgo === days ? "contained" : "outlined"}
                            onClick={() => this.onChangeUserDaysAgo(days)}
                            key={`${days}-user-button-days`}>
                            {this.daysAgoToString(days)}
                          </Button>
                        )
                      })}
                    </ButtonGroup>
                  </Box>
                </Grid>
                {Object.keys(totals).map(totalKey => {
                  return (
                    <Grid item xs={12} md={3} key={totalKey}>
                      <Box>
                        <TableContainer component={Paper}>
                          <Table aria-label={totalKey}>
                            <TableHead className={classes.tableHeader}>
                              <TableRow>
                                <TableCell colSpan={3} align="center">
                                  <Typography variant="h5">
                                    {totalKey === "all_time" ? "All" : ""} Users {totalKey === "all_time" ? "👪" : "🧑"}
                                  </Typography>
                                  <Typography variant="caption">
                                    {totalKey === "all_time" ? "All-time" : this.daysAgoToString(meta.userDaysAgo)}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {[["active_free_subscribers", "Free Trials"],
                              ["active_paying_subscribers", "Active Subscribers"],
                              ["cancelled_subscribers", "Cancellations"],
                              ["non_subscribers", "Non-Subscribers"],
                              ["active_cancelled_subscribers", "Active Cancellations"],
                              ["inactive_subscribers", "Re-trying"]].map(row => {
                                const key = row[0];
                                const title = row[1];
                                const total = totals[totalKey]["all"][key].length;
                                const web = totals[totalKey]["web"][key].length;
                                const iOS = totals[totalKey]["iOS"][key].length;
                                return (
                                  <TableRow key={`${totalKey}-${key}`}>
                                    <TableCell component="th" scope="row" align="right">
                                      <Typography variant="body2">
                                        {title}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="body2">
                                        {total}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="body2">
                                        {`(${iOS}-${web})`}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                              {["Conversions"].map(title => {
                                let conversions = {
                                  all: 0,
                                  web: 0,
                                  iOS: 0
                                };
                                Object.keys(conversions).forEach(key => {
                                  const subscribed = totals[totalKey][key].active_paying_subscribers.length;
                                  const cancelled = totals[totalKey][key].cancelled_subscribers.length + totals[totalKey][key].active_cancelled_subscribers.length;
                                  const non_subscribers = totals[totalKey][key].non_subscribers.length;
                                  conversions[key] = (100 * (subscribed / (subscribed + cancelled + non_subscribers))).toFixed(0);
                                })
                                return (
                                  <TableRow key={`${totalKey}-conversions`}>
                                    <TableCell component="th" scope="row" align="right">
                                      <Typography variant="body2">
                                        {title}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="body2">
                                        {conversions.all}%
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant="body2">
                                        {`(${conversions.iOS}%-${conversions.web}%)`}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Typography variant="body2" style={{ fontStyle: 'italic' }} align="right">
                          (iOS-web)
                        </Typography>
                      </Box>
                    </Grid>
                  )
                })
                }
                <Grid item xs={12} md={3} key="recent-signups">
                  <Box>
                    <TableContainer component={Paper}>
                      <Table aria-label={"Recent Signups"}>
                        <TableHead className={classes.tableHeader}>
                          <TableRow>
                            <TableCell colSpan={3} align="center">
                              <Typography variant="h5">
                                Recent Signups ✍️
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {recentSignups.map((value, i) => {
                            const paid = value.free_trial === true ? " (trial)" : (value.free_trial === null ? "" : " 💸");
                            return (value &&
                              <TableRow key={`recent-signups-${i}`}>
                                <TableCell component="th" scope="row" align="right">
                                  {moment.utc(value.timestamp).fromNow()}
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    {value.source}
                                  </Typography>
                                  <Typography variant="body2">
                                    <b>
                                      {value.tier}{paid}
                                    </b>
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    {value.platform}
                                  </Typography>
                                  {value.promo_code &&
                                    <Typography variant="caption">
                                      {`{${value.promo_code}}`}
                                    </Typography>
                                  }
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3} key="recent-cancellations">
                  <Box>
                    <TableContainer component={Paper}>
                      <Table aria-label={"Sources"}>
                        <TableHead className={classes.tableHeader}>
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              <Typography variant="h5">
                                Recent Cancellations 🫡
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {recentCancellations.map((value, i) => {
                            return (value &&
                              <TableRow key={`recent-cancellations-${i}`}>
                                <TableCell component="th" scope="row" align="right">
                                  {moment.utc(value.timestamp).fromNow()}
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    {value.reason}
                                    {(!value.reason || !value.reason.length > 0) &&
                                      <Button size="small" onClick={() => this.onSetCancellationReason(value.email, value.platform)}>
                                        ➕
                                      </Button>
                                    }
                                  </Typography>
                                  <Typography variant="body2">
                                    {value.email}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
                <Grid item xs={12} key={"tiers"}>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table aria-label={"Subscriber Tiers"}>
                        <TableHead className={classes.tableHeader}>
                          <TableRow>
                            {tiersKeys.map(key => {
                              return (
                                <TableCell align="center" key={`tiers-title-${key}`}>
                                  <Typography variant="h5">
                                    {key}
                                  </Typography>
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key={`subscriber-tiers`}>
                            {tiersKeys.map(key => {
                              return (
                                <TableCell component="th" scope="row" align="center" key={`tiers-${key}`}>
                                  {tiers && tiers[key]}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid >
        }
      </>
    );
  }
}

StatsPage.propTypes = {
  user: PropTypes.object,
  leagues: PropTypes.array.isRequired,
  sportsbooks: PropTypes.array.isRequired,
  propsites: PropTypes.array.isRequired,
  openSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(withWidth()(withRouter(StatsPage)));
