import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'

import {
  Typography,
  Box,
  Link,
  Fab
} from "@material-ui/core";

const TwitterFollowPrompt = () => {
  return (
    <Box px={2} py={4}>
      <Typography variant="subtitle2" align="center">
        Want to stay up on the latest with HammerPicks? Drop us a follow on&nbsp;
        <Link href="https://twitter.com/hammerpicksHQ"
          color="inherit"
          underline="always">
          X
        </Link>!
        <br />
        <br />
        <Fab color="primary" style={{ background: "#000000" }} href="https://twitter.com/hammerpicksHQ">
          <FontAwesomeIcon size="2xl" icon={faXTwitter} />
        </Fab>
      </Typography>
    </Box>
  );
};

export default TwitterFollowPrompt;