import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import EmptyState from "../EmptyState";

import { Fab } from "@material-ui/core";

import googleAnalytics from "../../services/googleAnalytics";
import images from "../../services/images";

class CheckoutSuccessPage extends Component {

  componentDidMount() {
    document.title = "Checkout Success | HammerPicks";
    googleAnalytics.logPageView(document.title);
  }

  render() {
    const url = `${process.env.REACT_APP_FRONTEND_ENDPOINT}/picks`;
    return (
      <EmptyState
        image={<img src={images.imageFor("success")} alt="success" />}
        title="Success"
        description="Congratulations! You now have access to the picks."
        button={
          <Fab style={{ fontWeight: 700 }} variant="extended" color="secondary" href={url}>
            Take Me To The Picks
          </Fab>
        }
        showTwitterFollowPrompt={true}
      />
    );
  }
}

CheckoutSuccessPage.propTypes = {
};

export default withRouter(CheckoutSuccessPage);
