import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import { Container, Grid, Box, Paper, Typography, Fab, Link } from "@material-ui/core";

class AppRedirectPage extends Component {

  render() {
    return (
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box p={2} m={2}>
              <Paper px={2}>
                <Box p={2} m={2}>
                  <Typography variant="h5" align="center">
                    Open App To Continue
                  </Typography>
                  <Box p={2}>
                    <Typography variant="body1" align="center">
                      <Fab style={{ fontWeight: 700 }} variant="extended" color="secondary" component={Link} href="hammerpicks://open">
                        Open App
                      </Fab>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

AppRedirectPage.propTypes = {
};

export default withRouter(AppRedirectPage);
