import React, { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import {
  Grid,
  Box,
  Container,
  TextField,
  Typography,
  Paper,
  Button,
} from "@material-ui/core";

import authentication from "../../services/authentication";
import googleAnalytics from "../../services/googleAnalytics";

import { withStyles } from "@material-ui/core/styles";
import EmptyState from "../EmptyState/EmptyState";

const styles = (theme) => ({
});

class PromoPage extends Component {

  handlePromoCodeChange = (event) => {
    const code = event.target.value;

    this.setState({
      promoCode: code,
    });
  }

  addPromo() {
    const {
      promoCode
    } = this.state;
    this.setState({
      performingAction: true
    }, () => {
      authentication.addPromoToNextPayment(promoCode)
        .then(() => {
          this.props.openSnackbar("Applied promo code");
          window.location.replace("/subscribe");
        })
        .catch((error) => {
          this.props.openSnackbar(error);
          this.setState({
            performingAction: false
          });
        })
    })
  }

  constructor(props) {
    super(props);

    const query = Object.fromEntries(new URLSearchParams(window.location.search));
    this.state = {
      promoCode: query.promo_code || ""
    };
  }

  componentDidMount() {
    document.title = "Promo | HammerPicks";
    googleAnalytics.logPageView(document.title);
  }

  render() {
    const { user } = this.props;
    const { promoCode } = this.state;

    if (!user) {
      return (
        <EmptyState
          title="No User"
          description="Please sign up or sign in to continue."
        />
      );
    }

    return (
      <Container>
        <Grid container spacing={2} style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Grid item lg={4} md={4} sm={3} />
          <Grid item xs={12} sm={6} md={4}>
            <Box component={Paper} p={3}>
              <Typography variant="h4">
                Claim Promo Code
              </Typography>
              <br />
              <TextField
                autoComplete="promo"
                disabled={false}
                fullWidth
                inputProps={{
                  autoCapitalize: 'none',
                }}
                label="Promo Code"
                placeholder="code"
                required
                type="text"
                value={promoCode}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={this.handlePromoCodeChange}
              />
              <br />
              <br />
              <Button
                color="primary"
                variant="contained"
                onClick={() => this.addPromo()}
              >
                Claim Promo
              </Button>
            </Box>
          </Grid>
        </Grid >
      </Container >
    );
  }
}

PromoPage.propTypes = {
  user: PropTypes.object,
  openSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(withRouter(PromoPage));
