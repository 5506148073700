import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Container,
} from "@material-ui/core";

import SubscriptionUpgrade from "../SubscriptionUpgrade";
import EmptyState from "../EmptyState";

class SubscriptionUpgradePage extends Component {

  render() {
    const {
      user
    } = this.props;
    if (!user) {
      return (
        <EmptyState
          title="No User"
          description="Please sign up or sign in to continue."
        />
      );
    }
    return (
      <Container>
        {user.subscription_tier !== "year" &&
          <SubscriptionUpgrade
            user={user}
            openSnackbar={this.props.openSnackbar}
          />
        }
      </Container>
    );
  }
}

SubscriptionUpgradePage.propTypes = {
  user: PropTypes.object,
  openSnackbar: PropTypes.func.isRequired
};

export default SubscriptionUpgradePage;
