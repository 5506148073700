import React, { Component } from "react";

import PropTypes from "prop-types";

import validate from "validate.js";

import { withStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  IconButton,
  Hidden,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

import constraints from "../../data/constraints";
import authentication from "../../services/authentication";

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },

  divider: {
    margin: "auto",
  },

  grid: {
    marginBottom: theme.spacing(2),
  },
});

const initialState = {
  performingAction: false,
  email: "",
  password: "",
  errors: null,
};

if (process.env.NODE_ENV === "development") {
  initialState.email = "hammerpicks88@gmail.com";
  initialState.password = "test123";
}

class SignInDialog extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  resetPassword = () => {
    const { email } = this.state;

    const errors = validate(
      {
        email: email,
      },
      {
        email: constraints.email,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          errors: null,
        },
        () => {
          this.setState(
            {
              performingAction: true,
            },
            () => {
              authentication
                .resetPassword(email)
                .then((value) => {
                  this.props.openSnackbar(`Sent password reset e-mail to ${email}`);
                })
                .catch((error) => {
                  this.props.openSnackbar(error);
                })
                .finally(() => {
                  this.setState({
                    performingAction: false,
                  });
                });
            }
          );
        }
      );
    }
  };

  signIn = () => {
    const { email, password } = this.state;

    const errors = validate(
      {
        email: email,
        password: password,
      },
      {
        email: constraints.email,
        password: constraints.password,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          performingAction: true,
          errors: null,
        },
        () => {
          authentication
            .signIn(email, password)
            .then((user) => {
              this.props.dialogProps.onClose(() => {
                this.props.openSnackbar(`Signed in as ${user.email}`);
              });
            })
            .catch((error) => {
              this.props.openSnackbar(error);
            })
            .finally(() => {
              this.setState({
                performingAction: false,
              });
            });
        }
      );
    }
  };

  handleKeyPress = (event) => {
    const { email, password } = this.state;

    if (!email && !password) {
      return;
    }

    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === "Enter") {
      if (email && password) {
        this.signIn();
      }
    }
  };

  handleExited = () => {
    this.setState(initialState);
  };

  handleEmailChange = (event) => {
    const email = event.target.value;

    this.setState({
      email: email,
    });
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;

    this.setState({
      password: password,
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Dialog Properties
    const { dialogProps } = this.props;

    const { performingAction, email, password, errors } = this.state;

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        disableBackdropClick={performingAction}
        disableEscapeKeyDown={performingAction}
        {...dialogProps}
        onKeyPress={this.handleKeyPress}
        onExited={this.handleExited}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6">Sign in to your account</Typography>

          <Tooltip title="Close">
            <IconButton
              className={classes.closeButton}
              disabled={performingAction}
              onClick={dialogProps.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Hidden xsDown>
            <Grid container direction="row">

              <Grid item xs={12}>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs>
                    <TextField
                      autoComplete="email"
                      disabled={performingAction}
                      error={!!(errors && errors.email)}
                      fullWidth
                      helperText={
                        errors && errors.email
                          ? errors.email[0]
                          : ""
                      }
                      inputProps={{
                        autoCapitalize: 'none',
                      }}
                      label="E-mail address"
                      placeholder="john@doe.com"
                      required
                      type="emailAddress"
                      value={email}
                      variant="outlined"
                      InputLabelProps={{ required: false }}
                      onChange={this.handleEmailChange}
                    />
                  </Grid>

                  <Grid item xs>
                    <TextField
                      autoComplete="current-password"
                      disabled={performingAction}
                      error={!!(errors && errors.password)}
                      fullWidth
                      helperText={
                        errors && errors.password ? errors.password[0] : ""
                      }
                      label="Password"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      required
                      type="password"
                      value={password}
                      variant="outlined"
                      InputLabelProps={{ required: false }}
                      onChange={this.handlePasswordChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>

          <Hidden smUp>

            <Grid container direction="column" spacing={2}>
              <Grid item xs>
                <TextField
                  autoComplete="email"
                  disabled={performingAction}
                  error={!!(errors && errors.email)}
                  fullWidth
                  helperText={
                    errors && errors.email ? errors.email[0] : ""
                  }
                  inputProps={{
                    autoCapitalize: 'none',
                  }}
                  label="E-mail address"
                  placeholder="john@doe.com"
                  required
                  type="emailAddress"
                  value={email}
                  variant="outlined"
                  InputLabelProps={{ required: false }}
                  onChange={this.handleEmailChange}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  autoComplete="current-password"
                  disabled={performingAction}
                  error={!!(errors && errors.password)}
                  fullWidth
                  helperText={
                    errors && errors.password ? errors.password[0] : ""
                  }
                  label="Password"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  required
                  type="password"
                  value={password}
                  variant="outlined"
                  InputLabelProps={{ required: false }}
                  onChange={this.handlePasswordChange}
                />
              </Grid>
            </Grid>
          </Hidden>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            disabled={!email || performingAction}
            variant="outlined"
            onClick={this.resetPassword}
          >
            Reset password
          </Button>

          <Button
            color="primary"
            disabled={!email || performingAction}
            variant="contained"
            onClick={() => this.signIn()}
          >
            Sign in
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SignInDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignInDialog);
