import React, { Component } from "react";

import PropTypes from "prop-types";

import CheckoutTiers from "../CheckoutTiers";
import authentication from "../../services/authentication";

const initialState = {
  performingAction: false,
};

class SubscriptionUpgrade extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  onChangeTierClicked = (tier) => {
    const response = window.confirm("You will be pro-rated the time remaining on your current subscription and you will be upgraded now. Continue?");
    if (response) {
      authentication.changeSubscriptionTier(tier)
        .then(() => {
          const title = `Success! Upgraded subscription tier to ${tier}`;
          this.props.openSnackbar(title);
          window.alert(title);
          document.location.href = "/";
        })
        .catch((error) => {
          this.props.openSnackbar(error);
        })
        .finally(() => {
          this.setState({
            performingAction: false,
          });
        });
    }
  }

  render() {
    const { user, openSnackbar } = this.props;

    return (
      <CheckoutTiers
        title="Want more bang for your buck?"
        description="Longer subscriptions cost less on average per day. Time left on your current subscription will be prorated to your new subscription -- it'll be cheaper than the listed price for the first period."
        buttonAction="Upgrade to"
        priceFontSize="h4"
        user={user}
        highlightedTiers={["6-month", "year"]}
        onCheckoutClicked={(tier) => this.onChangeTierClicked(tier)}
        openSnackbar={openSnackbar}
      />
    );
  }
}

SubscriptionUpgrade.propTypes = {
  user: PropTypes.object,
  openSnackbar: PropTypes.func.isRequired
};

export default SubscriptionUpgrade;