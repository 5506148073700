import React, { Component } from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import {
  DialogContent,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Hidden,
  Divider,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";

import {
  Person as PersonIcon,
  Email as EmailIcon,
  SportsFootball as SportsFootballIcon,
  Sort as SortIcon,
  FormatListNumbered as FormatListNumberedIcon,
  AccessTime as ClockIcon,
  NotificationsActive as NotificationsActiveIcon,
  MenuBook as MenuBookIcon,
  PhoneIphone as PhoneIphoneIcon,
  AllInbox as AllInboxIcon
} from "@material-ui/icons";

import MultiSelectField from "../MultiSelectField";

import authentication from "../../services/authentication";

import moment from "moment";

const styles = (theme) => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
  },

  badge: {
    top: theme.spacing(2),
    right: -theme.spacing(2),
  },

  loadingBadge: {
    top: "50%",
    right: "50%",
  },

  nameInitials: {
    cursor: "default",
  },

  personIcon: {
    fontSize: theme.spacing(7),
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    minHeight: "initial",
  },
});

const initialState = {
  showingField: "",
  firstName: "",
  lastName: "",
  email: "",
  performingAction: false,
  sentVerificationEmail: false,
  errors: null,
};

class AccountTab extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  onUpdateTimezoneSetting = (event) => {
    if (!event) { return; }

    const timezone = event.target.value;
    authentication.changeTimezoneSetting(timezone)
      .then(() => {
        this.props.openSnackbar("Updated timezone setting");
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
      .finally(() => {
        this.setState({
          performingAction: false,
        });
      });
  };

  onUpdateEmailSetting = (event) => {
    if (!event) { return; }

    const isEnabled = event.target.checked;
    authentication.changeEmailUpdatesSetting(isEnabled)
      .then(() => {
        this.props.openSnackbar("Updated e-mail updates setting");
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
      .finally(() => {
        this.setState({
          performingAction: false,
        });
      });
  };

  onUpdateiOSSetting = (event) => {
    if (!event) { return; }

    const isEnabled = event.target.checked;
    authentication.changeiOSUpdatesSetting(isEnabled)
      .then(() => {
        this.props.openSnackbar("Updated iOS updates setting");
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
      .finally(() => {
        this.setState({
          performingAction: false,
        });
      });
  };

  onUpdateMarketingEmailSetting = (event) => {
    if (!event) { return; }

    const isEnabled = event.target.checked;
    authentication.changeMarketingEmailUpdatesSetting(isEnabled)
      .then(() => {
        this.props.openSnackbar("Updated email updates setting");
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
      .finally(() => {
        this.setState({
          performingAction: false,
        });
      });
  };

  onUpdateUpdatesIntervalSetting = (event) => {
    if (!event) { return; }

    const updatesInterval = event.target.value;
    authentication.changeUpdatesIntervalSetting(updatesInterval)
      .then(() => {
        this.props.openSnackbar("Updated updates interval setting");
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
      .finally(() => {
        this.setState({
          performingAction: false,
        });
      });
  }

  onUpdateLeaguesSetting = (leagues) => {
    authentication.changeLeaguesSetting(leagues)
      .then(() => {
        this.props.openSnackbar("Updated leagues setting");
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
      .finally(() => {
        this.setState({
          performingAction: false,
        });
      });
  };

  onUpdateSportsbooksSetting = (sportsbooks) => {
    authentication.changeSportsbooksSetting(sportsbooks)
      .then(() => {
        this.props.openSnackbar("Updated sportsbooks setting");
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
      .finally(() => {
        this.setState({
          performingAction: false,
        });
      });
  };

  onUpdatePropsitesSetting = (propsites) => {
    authentication.changePropsitesSetting(propsites)
      .then(() => {
        this.props.openSnackbar("Updated propsites setting");
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
      .finally(() => {
        this.setState({
          performingAction: false,
        });
      });
  };

  onUpdatePicksSortSetting = (event) => {
    const value = event.target.value;
    authentication.changePicksSortSetting(value)
      .then(() => {
        this.props.openSnackbar("Updated picks sort setting");
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
      .finally(() => {
        this.setState({
          performingAction: false,
        });
      });
  };

  onUpdateEmailScoreThresholdSetting = (event) => {
    const value = event.target.value;
    authentication.changeEmailScoreThresholdSetting(value)
      .then(() => {
        this.props.openSnackbar("Updated email score threshold setting");
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
      .finally(() => {
        this.setState({
          performingAction: false,
        });
      });
  };

  render() {
    const { classes, user, leagues, sportsbooks, propsites } = this.props;
    const { performingAction } = this.state;

    let picksSort = "SCORE";
    if (user.picks_sort) {
      picksSort = user.picks_sort;
    }

    let emailScoreThreshold = 6;
    if (user.email_score_threshold) {
      emailScoreThreshold = user.email_score_threshold;
    }

    let updatesInterval = 30;
    if (user.updates_interval) {
      updatesInterval = user.updates_interval;
    }

    return (
      <DialogContent classes={{ root: classes.dialogContent }}>
        <List disablePadding>
          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
            </Hidden>
            <ListItemText
              primary={`${user.first_name} ${user.last_name}`}
              secondary={user.email}
            />
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <ClockIcon />
              </ListItemIcon>
            </Hidden>
            <ListItemText
              primary="Timezone"
            />
            <ListItemSecondaryAction>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Timezone</InputLabel>
                <Select
                  labelId="sort-select-outlined-label"
                  id="sort-select-outlined"
                  value={user.timezone}
                  onChange={this.onUpdateTimezoneSetting}
                  label="Timezone"
                >
                  {moment.tz.names().map(timezone => {
                    return (
                      <MenuItem value={timezone}>{timezone}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </ListItemSecondaryAction>
          </ListItem>

          < ListItem >
            <Hidden xsDown>
              <ListItemIcon>
                <AllInboxIcon />
              </ListItemIcon>
            </Hidden>
            <ListItemText
              primary="HammerPicks Email Updates"
              secondary="2-3 emails per year max with updates/promotions"
            />
            <ListItemSecondaryAction>
              <Checkbox
                color="secondary"
                defaultChecked={user.marketing_updates}
                disabled={performingAction}
                variant="contained"
                onClick={this.onUpdateMarketingEmailSetting}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <Box mt={1} mb={1}>
            <Divider light />
          </Box>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <SportsFootballIcon />
              </ListItemIcon>
            </Hidden>
            <ListItemText
              primary="My Leagues"
              secondary="Choose which leagues you want picks for"
              style={{ paddingRight: "60px" }}
            />
            <ListItemSecondaryAction>
              <MultiSelectField
                key={"league"}
                options={leagues}
                selected={user.leagues}
                selectAllIfNone={true}
                name={"Leagues"}
                onClose={this.onUpdateLeaguesSetting}>
              </MultiSelectField>
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <MenuBookIcon />
              </ListItemIcon>
            </Hidden>
            <ListItemText
              primary="My Sportsbooks"
              secondary="Choose which sportsbooks you want picks for"
              style={{ paddingRight: "60px" }}
            />
            <ListItemSecondaryAction>
              <MultiSelectField
                key={"sportsbook"}
                options={sportsbooks}
                selected={user.sportsbooks}
                selectAllIfNone={true}
                name={"Sportsbooks"}
                onClose={this.onUpdateSportsbooksSetting}>
              </MultiSelectField>
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <PhoneIphoneIcon />
              </ListItemIcon>
            </Hidden>
            <ListItemText
              primary="My Propsites"
              secondary="Choose which propsites you want picks for"
              style={{ paddingRight: "60px" }}
            />
            <ListItemSecondaryAction>
              <MultiSelectField
                key={"propsite"}
                options={propsites}
                selected={user.propsites}
                selectAllIfNone={true}
                name={"Propsites"}
                onClose={this.onUpdatePropsitesSetting}>
              </MultiSelectField>
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <SortIcon />
              </ListItemIcon>
            </Hidden>
            <ListItemText
              primary="Picks sort"
              secondary="Choose in what order your picks appear"
              style={{ paddingRight: "60px" }}
            />
            <ListItemSecondaryAction>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Sort</InputLabel>
                <Select
                  labelId="sort-select-outlined-label"
                  id="sort-select-outlined"
                  value={picksSort}
                  onChange={this.onUpdatePicksSortSetting}
                  label="Sort"
                >
                  <MenuItem value={"SCORE"}>Score</MenuItem>
                  <MenuItem value={"ODDS"}>Odds</MenuItem>
                </Select>
              </FormControl>
            </ListItemSecondaryAction>
          </ListItem>

          <Box mt={1} mb={1}>
            <Divider light />
          </Box>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
            </Hidden>
            <ListItemText
              primary="New Picks Email Updates"
              secondary="Every update interval (default: 30mins)"
            />
            <ListItemSecondaryAction>
              <Checkbox
                color="secondary"
                defaultChecked={user.email_updates}
                disabled={performingAction}
                variant="contained"
                onClick={this.onUpdateEmailSetting}
              />
            </ListItemSecondaryAction>
          </ListItem>

          < ListItem >
            <Hidden xsDown>
              <ListItemIcon>
                <NotificationsActiveIcon />
              </ListItemIcon>
            </Hidden>
            <ListItemText
              primary="New Picks iOS Push Updates"
              secondary="Every update interval (default: 30mins)"
            />
            <ListItemSecondaryAction>
              <Checkbox
                color="secondary"
                defaultChecked={user.ios_updates}
                disabled={performingAction}
                variant="contained"
                onClick={this.onUpdateiOSSetting}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <ClockIcon />
              </ListItemIcon>
            </Hidden>
            <ListItemText
              primary="Update interval"
              secondary="Get email and/or push updates every..."
              style={{ paddingRight: "60px" }}
            />
            <ListItemSecondaryAction>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Interval</InputLabel>
                <Select
                  labelId="sort-select-outlined-label"
                  id="sort-select-outlined"
                  value={updatesInterval}
                  onChange={this.onUpdateUpdatesIntervalSetting}
                  label="Score"
                >
                  <MenuItem value={30}>30 mins</MenuItem>
                  <MenuItem value={15}>15 mins</MenuItem>
                  <MenuItem value={10}>10 mins</MenuItem>
                </Select>
              </FormControl>
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <FormatListNumberedIcon />
              </ListItemIcon>
            </Hidden>
            <ListItemText
              primary="Score threshold"
              secondary="Only get pick updates if they reach a certain score"
              style={{ paddingRight: "60px" }}
            />
            <ListItemSecondaryAction>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Score</InputLabel>
                <Select
                  labelId="sort-select-outlined-label"
                  id="sort-select-outlined"
                  value={emailScoreThreshold}
                  onChange={this.onUpdateEmailScoreThresholdSetting}
                  label="Score"
                >
                  <MenuItem value={0}>Any Score</MenuItem>
                  <MenuItem value={1}>1+</MenuItem>
                  <MenuItem value={2}>2+</MenuItem>
                  <MenuItem value={3}>3+</MenuItem>
                  <MenuItem value={4}>4+</MenuItem>
                  <MenuItem value={5}>5+</MenuItem>
                  <MenuItem value={6}>6+</MenuItem>
                  <MenuItem value={7}>7+</MenuItem>
                  <MenuItem value={8}>8+</MenuItem>
                  <MenuItem value={9}>9+</MenuItem>
                </Select>
              </FormControl>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </DialogContent >
    );
  }
}

AccountTab.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Properties
  user: PropTypes.object.isRequired,
  leagues: PropTypes.array.isRequired,
  sportsbooks: PropTypes.array.isRequired,
  propsites: PropTypes.array.isRequired,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(AccountTab);
