import React, { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import {
  Button,
  ButtonGroup
} from "@material-ui/core";

import LaunchScreen from "../LaunchScreen";
import EmptyState from "../EmptyState";

import googleAnalytics from "../../services/googleAnalytics";

class ManagePaymentPage extends Component {

  componentDidMount() {
    document.title = "Manage Payment | HammerPicks";
    googleAnalytics.logPageView(document.title);
  }

  render() {
    const { user, onCustomerPortalClicked } = this.props;

    if (!user) {
      return (
        <LaunchScreen />
      )
    }

    return (
      <>
        {user.subscription_platform === "stripe" &&
          <EmptyState
            title="Manage Payment"
            description="Click below to manage your subscription payment."
            button={
              <ButtonGroup
                color="secondary"
                variant="outlined"
              >
                <Button onClick={() => onCustomerPortalClicked(true)}>Cancel</Button>
                <Button onClick={() => onCustomerPortalClicked(false)}>Manage</Button>
              </ButtonGroup>
            }
          />
        }
        {user.subscription_platform === "iOS" &&
          <EmptyState
            title="Manage Payment"
            description="Open the Settings app. Tap your name. Tap Subscriptions"
          />
        }
      </>
    );
  }
}

ManagePaymentPage.propTypes = {
  user: PropTypes.object,
  onCustomerPortalClicked: PropTypes.func.isRequired,
};

export default withRouter(ManagePaymentPage);
