import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import parse from 'html-react-parser'
import moment from 'moment';

import { Container, Grid, Box, Paper, Typography } from "@material-ui/core";

import LaunchScreen from "../LaunchScreen";

import googleAnalytics from "../../services/googleAnalytics";
import { firestore } from '../../firebase';

class ArticlePage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      article: null,
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.articlesSnapshotListener = firestore.collection("articles").doc(id).onSnapshot((snapshot) => {
      if (!snapshot || snapshot.isEmpty) {
        return;
      }

      const article = snapshot.data();

      document.title = `${article.title} | HammerPicks`;
      googleAnalytics.logPageView(document.title);

      this.setState({
        ready: true,
        article: article,
      });
    }, (error) => {
      console.log(error);
    });
  }

  componentWillUnmount() {
    if (this.articlesSnapshotListener) {
      this.articlesSnapshotListener();
    }
  }

  render() {
    const { ready, article } = this.state;

    if (!ready || !article) {
      return (
        <LaunchScreen />
      )
    }
    return (
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box p={2} m={2}>
              <Paper px={2}>
                <Box p={2} m={2}>
                  <Typography variant="h3">
                    {article.title}
                  </Typography>
                  <br />
                  <Typography variant="subtitle1" style={{ color: "gray" }}>
                    {article.author} | {moment(article.publishedAt.toDate()).format('MMMM Do, h:mm a')}
                  </Typography>
                  <br />
                  <Typography variant="body1">
                    {parse(article.html)}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

ArticlePage.propTypes = {
};

export default withRouter(ArticlePage);
