const util = {};

util.propsiteTitled = (propsite) => {
    switch (propsite) {
        case "prizepicks":
            return "PrizePicks";
        case "nohouseadvantage":
            return "NHA";
        case "parlayplay":
            return "ParlayPlay"
        case "pick6":
            return "Pick6"
        default:
            return propsite.charAt(0).toUpperCase() + propsite.substr(1);
    }
}

util.propsitesCapitalizedXPrizePicks = (propsites) => {
    return propsites.flatMap(propsite => {
        switch (propsite) {
            case "prizepicks":
                return [];
            case "nohouseadvantage":
                return "NoHouseAdvantage";
            case "parlayplay":
                return "ParlayPlay"
            case "pick6":
                return "Pick6"
            default:
                return propsite.charAt(0).toUpperCase() + propsite.substr(1);
        }
    })
};

export default util;
