import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import {
  Grid,
  Typography,
  Box,
  Fab,
  Grow,
  Link,
  Container,
  Paper,
} from "@material-ui/core";

import Rating from '@material-ui/lab/Rating';
import { CircularProgress } from "@material-ui/core";

import CheckoutTiers from "../CheckoutTiers";

import Image from 'material-ui-image'
import withWidth from '@material-ui/core/withWidth';

import images from "../../services/images";
import stats from "../../services/stats";
import util from "../../services/util";

const styles = (theme) => ({
  spanHighlight: {
    color: theme.palette.secondary.main
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

class HomeSplashPage extends Component {

  constructor(props) {
    super(props);

    /*
    stats: {
        "users": 180,
        "hammers": 650,
        "percentage": .67
      }
    */
    this.state = {
      ready: false
    };
  }

  getStats = () => {
    stats.getStatsHome()
      .then((data) => {
        this.setState({
          ready: true,
          stats: data
        });
      })
      .catch((error) => {
        this.props.openSnackbar(error);
        this.setState({
          ready: true,
        });
      })
  }

  componentDidMount() {
    this.getStats();
  }

  render() {
    const {
      user,
      classes,
      //width,
      onSignUpClicked,
      onFAQClicked,
      onCheckoutClicked,
      openSnackbar
    } = this.props;

    let { propsites } = this.props;
    propsites = util.propsitesCapitalizedXPrizePicks(propsites);
    const {
      ready,
      stats
    } = this.state;

    //const isSmallScreen = width === "xs" || width === "sm";

    const query = Object.fromEntries(new URLSearchParams(window.location.search));
    const disableCheckoutTiers = query["hide"] && query["hide"].split(",").includes("checkouttiers");

    return (
      <>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} md={7}>
              <Box px={{ xs: 0, sm: 2 }} py={2} m={{ xs: 0, sm: 2 }}>
                <Typography variant="h3">
                  It's not free money, but it's close...
                </Typography>
                <Typography variant="h5">
                  <br />
                  HammerPicks is a real-time feed of PrizePicks* props that signal a clear Vegas edge. We find the picks, all you have to do is Hammer them.
                </Typography>
                <br />
                {ready && <Box component={Paper} p={3}>
                  <Typography variant="h6">
                    Join <span className={classes.spanHighlight}>{(stats && `${stats.users}+`) || "..."}</span> users who were notified of <span className={classes.spanHighlight}>{(stats && `${stats.hammers}+`) || "..."}</span> hammer picks in the past month. Our top picks had an average hit rate of <span className={classes.spanHighlight}>{(stats && (100 * stats.percentage).toFixed(1)) || "..."}%</span> 💰🔨
                  </Typography>
                </Box>}
                {!ready && <Box component={Paper} p={3} className={classes.center}>
                  <CircularProgress />
                </Box>}
                <Typography variant="h6">
                  <br />
                  With a premium subscription you get unlimited access to:
                </Typography>
                <Typography variant="subtitle1">
                  <ul>
                    <li>A curated (and customizable) list of favorable prop picks</li>
                    <li>Optional email alerts for updated lines and picks</li>
                    <li>New leagues and props added all the time</li>
                  </ul>
                </Typography>
                <br />
                <br />
                {!disableCheckoutTiers &&
                  <Fab style={{ fontWeight: 700, marginRight: 8 }} variant="extended" color="secondary" onClick={() => onSignUpClicked()}>
                    Sign Up & Subscribe
                  </Fab>
                }
                <Link style={{ "cursor": "pointer" }} onClick={() => onFAQClicked()} underline="always">
                  More Info
                </Link>
                <br />
                <br />
                <Typography variant="subtitle1">
                  *We also support {propsites.join(", ")}
                </Typography>
                <br />
                <br />
                <Rating name="half-rating-read" defaultValue={4.8} precision={0.1} size="small" readonly />
                <br />
                <Typography variant="h5">
                  4.8/5 stars
                </Typography>
              </Box>
            </Grid >
            <Grid item xs={12} md={5}>
              <Box p={2} m={2}>
                <Grow in={true}>
                  <img
                    style={{ width: '100%' }}
                    alt='Mobile Picks'
                    src={"./images/mobile_picks.png"}
                  />
                </Grow>
              </Box>
            </Grid>
          </Grid >
        </Container>

        <Box>
          <Typography variant="h4" align="center" style={{
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}>
            🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨
          </Typography>
        </Box>

        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Box mt={{ xs: 4, sm: 12 }} className="dont-reader-mode-me comment" style={{ textAlign: "center" }}>
                <Typography variant="h1">
                  🔨 = 🤑
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box p={{ xs: 0, sm: 5 }} className="dont-reader-mode-me comment">
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  <br />
                  What is PrizePicks? {propsites.join("? ")}?
                </Typography>
                <Typography variant="body1">
                  They are fantasy apps/sites where you create multi-leg prop entries. Choose between 2 and 6 props, and win up to 15x your entry amount.
                </Typography>
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  <br />
                  What does HammerPicks actually do?
                </Typography>
                <Typography variant="body1">
                  PrizePicks* effectively pays out every prop like it’s 50/50. HammerPicks knows that most prop’s true odds are not actually 50/50. We find prop lines with implied probabilities in your favor, letting you gain a significant edge.
                </Typography>
              </Box>
            </Grid >
          </Grid >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Box p={{ xs: 0, sm: 5 }} mt={{ xs: 4, sm: 0 }} className="dont-reader-mode-me comment">
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  What leagues do you support?
                </Typography>
                <Typography variant="body1">
                  We currently support NFL, NBA, MLB, NHL, College Football, College Basketball, PGA, Tennis, and Lacrosse. More leagues are being added as they become available.</Typography>
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  <br />
                  What props do you support?
                </Typography>
                <Typography variant="body1">
                  We support the main ones that have decent data backing up the pick. For example, in NFL we currently support Pass Yards, Pass TDs, Pass Attempts, Pass Completions, Pass+Rush Yards, Rush Yards, Rush Attempts, Receptions, Receiving Yards, Rush+Rec Yards, FG Made, PAT Made, Tackles, and Sacks. More props are constantly added as the data dictates.
                </Typography>
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  <br />
                  Terms
                </Typography>
                <Typography variant="body1">
                  No guarantee of wins or other monetary gain is implied by usage of our picks. Please see&nbsp;
                  <Link href="https://docs.google.com/document/d/e/2PACX-1vTarBi5-3aaQOWj2pddFrW2QhxBswt40H6TylsPe6kbYQSYPsCDoN8MStifOJ5h65hYFbVTMcRpa5KQ/pub"
                    color="inherit"
                    underline="always">
                    our terms
                  </Link>. Your subscription will continue unless canceled at least 24 hours before the end of the period.
                </Typography>
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  <br />
                  Can I use this website on mobile?
                </Typography>
                <Typography variant="body1">
                  We support both desktop and mobile versions of the picks for you to enjoy on whatever device you choose.
                </Typography>
              </Box>
            </Grid >
            <Grid item xs={12} sm={6}>
              <Box px={5} pt={5}>
                <Grow in={true}>
                  <Image
                    alt='Home'
                    src={images.imageFor("home")}
                  />
                </Grow>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Box py={4} >
          <Typography variant="h4" align="center" style={{
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}>
            🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨
          </Typography>

          <Typography variant="subtitle1" align="center">
            *We also support {propsites.join(", ")}
          </Typography>
        </Box>

        {!disableCheckoutTiers &&
          <CheckoutTiers
            title="Subscribe to HammerPicks"
            description="Choose your subscription length. The longer the subscription, the more the discount. Cancel anytime."
            user={user}
            disableCheckoutTiers={["year"]}
            highlightedTiers={["week", "month", "6-month", "year"]}
            onCheckoutClicked={onCheckoutClicked}
            openSnackbar={openSnackbar}
          />
        }


        <Container>
          <Box px={{ xs: 0, sm: 4 }}>
            <Grow in={true} style={{ margin: "auto", display: "block" }}>
              <img
                alt='Desktop Picks'
                style={{ width: '100%' }}
                src={"./images/desktop_picks_cropped.png"}
              />
            </Grow>
          </Box>
        </Container>

      </>
    );
  }
}

HomeSplashPage.propTypes = {
  user: PropTypes.object,
  propsites: PropTypes.array.isRequired,
  onCheckoutClicked: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(withWidth()(HomeSplashPage));
