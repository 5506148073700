import React, { Component } from "react";

import PropTypes from "prop-types";

import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Typography,
  Fab,
  Box
} from "@material-ui/core";

class SharePrompt extends Component {

  render() {
    const { text, copyText, openSnackbar } = this.props;

    return (
      <>
        <Typography variant="h5" align="center">
          Refer-A-Friend
        </Typography>
        <Box p={2}>
          <Typography variant="body1" align="center">
            {text}
          </Typography>
        </Box>
        <Box p={2}>
          <Typography variant="body1" align="center">
            <CopyToClipboard text={copyText} onCopy={() => openSnackbar("Copied")}>
              <Fab style={{ fontWeight: 700, marginLeft: 12 }} variant="extended" color="primary">
                Copy Link
              </Fab>
            </CopyToClipboard>
          </Typography>
        </Box>
      </>
    );
  }
}

SharePrompt.propTypes = {
  text: PropTypes.string.isRequired,
  copyText: PropTypes.string.isRequired,
  openSnackbar: PropTypes.func.isRequired,
};

export default SharePrompt;
