import React from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Tooltip,
  IconButton,
  Link
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { Close as CloseIcon } from "@material-ui/icons";

import util from "../../services/util";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

function FAQDialog(props) {
  const classes = useStyles();
  const { dialogProps } = props;
  let { propsites } = props;
  propsites = util.propsitesCapitalizedXPrizePicks(propsites);

  return (
    <Dialog fullWidth maxWidth="md" {...dialogProps}>
      <DialogTitle disableTypography>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          FAQ
        </Typography>

        <Tooltip title="Close">
          <IconButton
            className={classes.closeButton}
            onClick={dialogProps.onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          What is PrizePicks? {propsites.join("? ")}?
        </Typography>
        <Typography variant="body1">
          They are fantasy apps/sites where you create multi-leg prop entries. Choose between 2 and 5 props, and win up to 10x your entry amount.
        </Typography>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          What does HammerPicks actually do?
        </Typography>
        <Typography variant="body1">
          PrizePicks* effectively pays out every prop like it’s 50/50. HammerPicks knows that most prop’s true odds are not actually 50/50. We find prop lines with implied probabilities in your favor, letting you gain a significant edge.
          <br />
          <br />
          *We also support {propsites.join(", ")}
        </Typography>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          What leagues do you support?
        </Typography>
        <Typography variant="body1">
          We currently support NFL, NBA, MLB, College Football, College Basketball, PGA, Tennis, and Lacrosse. More leagues are being added as they become available.</Typography>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          What props do you support?
        </Typography>
        <Typography variant="body1">
          We support the main ones that have decent data backing up the pick. For example, in NFL we currently support Pass Yards, Pass TDs, Pass Attempts, Pass Completions, Pass+Rush Yards, Rush Yards, Rush Attempts, Receptions, Receiving Yards, Rush+Rec Yards, FG Made, PAT Made, Tackles, and Sacks. More props are constantly added as the data dictates.
        </Typography>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          How do I manage my subscription?
        </Typography>
        <Typography variant="body1">
          You can manage your subscription by clicking the settings icon (upper right), then "Settings", then "Subscription", then "Cancel" to update your subscription settings.
        </Typography>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          Terms
        </Typography>
        <Typography variant="body1">
          No guarantee of wins or other monetary gain is implied by usage of our picks. Please see&nbsp;
          <Link href="https://docs.google.com/document/d/e/2PACX-1vTarBi5-3aaQOWj2pddFrW2QhxBswt40H6TylsPe6kbYQSYPsCDoN8MStifOJ5h65hYFbVTMcRpa5KQ/pub"
            color="inherit"
            underline="always">
            our terms
          </Link>. Your subscription will continue unless canceled at least 24 hours before the end of the period.
        </Typography>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          Can I use this website on mobile?
        </Typography>
        <Typography variant="body1">
          We support both desktop and mobile versions of the picks for you to enjoy on whatever device you choose.
        </Typography>
        <br />
      </DialogContent>
    </Dialog>
  );
}

FAQDialog.propTypes = {
  dialogProps: PropTypes.object.isRequired,
  propsites: PropTypes.array.isRequired,
};

export default FAQDialog;
