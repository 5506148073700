import React, { Component } from "react";

import PropTypes from "prop-types";

import { withRouter, Link } from "react-router-dom";

import {
  Fab,
} from "@material-ui/core";

import EmptyState from "../EmptyState";
import HomeSplashPage from "../HomeSplashPage";
import SubscribeSplashPage from "../SubscribeSplashPage";

import googleAnalytics from "../../services/googleAnalytics";
import authentication from "../../services/authentication";

import moment from "moment";
import images from "../../services/images";

class HomePage extends Component {

  refreshPage = () => {
    window.location = "/subscribe";
  }

  sendEmailVerificationEmail = () => {
    authentication
      .sendEmailVerificationEmail()
      .then((value) => {
        this.props.openSnackbar(`Sent verification e-mail. Check your spam if its not in your inbox.`);
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
      .finally(() => {
        this.setState({
          performingAction: false,
        });
      });
  }

  componentDidMount() {
    document.title = "Home | HammerPicks";
    googleAnalytics.logPageView(document.title);
  }

  render() {
    const { user, propsites, onSignUpClicked, onFAQClicked, onCheckoutClicked, openSnackbar } = this.props;

    if (!user) {
      return (
        <HomeSplashPage
          user={user}
          propsites={propsites}
          onSignUpClicked={onSignUpClicked}
          onFAQClicked={onFAQClicked}
          onCheckoutClicked={(tier) => {
            window.parent.location.hash = tier;
            onSignUpClicked();
          }}
          openSnackbar={openSnackbar}
        />
      )
    }

    if (!user.is_email_verified || user.is_email_verified == null) {
      return (
        <>
          <EmptyState
            image={<img src={images.imageFor("unverified")} alt="Unverified" />}
            title={`Welcome, ${user.first_name}`}
            description={`Check your email (${user.email}) for a verification email we just sent. Just click the link and you'll be good to go. If it
            seems like you didn't get it, check your spam folder.`}
            button={
              <Fab style={{ fontWeight: 700 }} variant="extended" color="secondary" onClick={this.sendEmailVerificationEmail}>
                Resend Verify Email
              </Fab>
            }
            link={
              <button style={{ marginLeft: 20 }} onClick={this.refreshPage}>I've Already Verified</button>
            }
          />
        </>
      );
    }

    const hasActiveSubscription = user.subscription_ends_at && moment.utc(user.subscription_ends_at) > new Date();
    if (hasActiveSubscription) {
      return (
        <EmptyState
          image={<img src={images.imageFor("welcome_back")} alt="Welcome Back" />}
          title={`Welcome Back, ${user.first_name}`}
          description="Hope you're having a good day."
          button={
            <Fab style={{ fontWeight: 700 }} variant="extended" color="secondary" component={Link} to="/picks">
              Take Me To The Picks
            </Fab>
          }
          showTwitterFollowPrompt={true}
        />
      );
    }

    return (
      <SubscribeSplashPage
        user={user}
        onCheckoutClicked={onCheckoutClicked}
        openSnackbar={openSnackbar}
      />
    );
  }
}

HomePage.propTypes = {
  user: PropTypes.object,
  propsites: PropTypes.array.isRequired,

  onCheckoutClicked: PropTypes.func.isRequired,
  onSignUpClicked: PropTypes.func.isRequired,
  onCustomerPortalClicked: PropTypes.func.isRequired,
  onFAQClicked: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired
};

export default withRouter(HomePage);
