import React, { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import {
  Grid,
  Box,
  Container,
  TextField,
  Typography,
  Paper,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Hidden,
  Checkbox,
} from "@material-ui/core";

import {
  Email as EmailIcon,
  AllInbox as AllInboxIcon
} from "@material-ui/icons";

import authentication from "../../services/authentication";
import googleAnalytics from "../../services/googleAnalytics";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
});

class EmailSettingsPage extends Component {

  handleEmailChange = (event) => {
    if (!event) { return; }
    const email = event.target.value;

    this.setState({
      email: email,
    });
  }

  onUpdateMarketingUpdatesSetting = (event) => {
    if (!event) { return; }
    const isEnabled = event.target.checked;

    this.setState({
      marketingUpdates: isEnabled,
    });
  }

  onUpdateEmailSetting = (event) => {
    if (!event) { return; }

    const isEnabled = event.target.checked;
    this.setState({
      emailUpdates: isEnabled,
    });
  };

  saveEmailSettings() {
    const {
      email,
      marketingUpdates,
      emailUpdates
    } = this.state;
    this.setState({
      performingAction: true
    }, () => {
      const data = { marketing_updates: marketingUpdates, email_updates: emailUpdates };
      authentication.updateEmailSettings(email, data)
        .then(() => {
          this.props.openSnackbar("Updated email settings");
          window.location.replace("/");
        })
        .catch((error) => {
          this.props.openSnackbar(error);
          this.setState({
            performingAction: false
          });
        })
    })
  }

  constructor(props) {
    super(props);

    const query = Object.fromEntries(new URLSearchParams(window.location.search));
    var marketing_updates = true;
    var email_updates = true;
    var email;
    if (props.user) {
      email = props.user.email;
      marketing_updates = props.user.marketing_updates;
      email_updates = props.user.email_updates;
    }
    this.state = {
      email: email || query.email || "",
      marketingUpdates: marketing_updates,
      emailUpdates: email_updates,
    };
  }

  componentDidMount() {
    document.title = "Email Settings | HammerPicks";
    googleAnalytics.logPageView(document.title);
  }

  render() {
    const { email, marketingUpdates, emailUpdates } = this.state;

    return (
      <Container>
        <Grid container spacing={2} style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Grid item lg={3} md={3} sm={2} />
          <Grid item xs={12} sm={8} md={6}>
            <Box component={Paper} p={3}>
              <Typography variant="h4">
                Email Settings
              </Typography>
              <br />
              <List disablePadding>
                <ListItem>
                  <TextField
                    autoComplete="email"
                    disabled={false}
                    fullWidth
                    inputProps={{
                      autoCapitalize: 'none',
                    }}
                    label="Email"
                    placeholder="email"
                    required
                    type="email"
                    value={email}
                    variant="outlined"
                    InputLabelProps={{ required: true }}
                    onChange={this.handleEmailChange}
                  />
                </ListItem>
                < ListItem >
                  <Hidden xsDown>
                    <ListItemIcon>
                      <AllInboxIcon />
                    </ListItemIcon>
                  </Hidden>
                  <ListItemText
                    primary="HammerPicks Email Updates"
                    secondary="2-3 emails per year max with updates/promotions"
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      color="secondary"
                      defaultChecked={marketingUpdates}
                      disabled={false}
                      variant="contained"
                      onClick={this.onUpdateMarketingUpdatesSetting}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <Hidden xsDown>
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                  </Hidden>
                  <ListItemText
                    primary="New Picks Email Updates"
                    secondary="Every update interval (default: 30mins)"
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      color="secondary"
                      defaultChecked={emailUpdates}
                      disabled={false}
                      variant="contained"
                      onClick={this.onUpdateEmailSetting}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
              <br />
              <Button
                color="primary"
                variant="contained"
                onClick={() => this.saveEmailSettings()}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid >
      </Container >
    );
  }
}

EmailSettingsPage.propTypes = {
  user: PropTypes.object,
  openSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(withRouter(EmailSettingsPage));
