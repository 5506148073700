import React, { Component } from "react";

import {
  Container,
  Typography
} from "@material-ui/core";


class PicksTutorialPage extends Component {

  render() {
    return (
      <Container style={{ paddingBottom: "40px" }}>
        <img src="images/pick_info.png" alt="Pick Info" />
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          What is a hammer?
        </Typography>
        <Typography variant="body1" style={{ color: "gray" }}>
          Hammers have the highest scores, which are good. Look for the 🔨 emoji(s) next to the pick.
        </Typography>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          What does bold mean?
        </Typography>
        <Typography variant="body1" style={{ color: "gray" }}>
          Bold means that the pick's score has updated recently, which usually means in the last 30 minutes (this is the default). You can select an "update interval" to customize this time.
        </Typography>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          How do I get email or iOS alerts for new/updated picks?
        </Typography>
        <Typography variant="body1" style={{ color: "gray" }}>
          Select it in Settings. Click the gear icon (upper right), go to "Settings" and toggle on Email and/or iOS Updates. You can also set a score threshold (and update interval) to customize which (and when) picks are sent to you.
        </Typography>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          Why are there so many picks?
        </Typography>
        <Typography variant="body1" style={{ color: "gray" }}>
          We score almost all available picks. We do this so you can still choose the picks you like.
        </Typography>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          I don't care about "X" league
        </Typography>
        <Typography variant="body1" style={{ color: "gray" }}>
          Manage it in Settings. Click the gear icon (upper right), go to "Settings" and choose which leagues you want in My Leagues.
        </Typography>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          I don't care about "X" sportsbook
        </Typography>
        <Typography variant="body1" style={{ color: "gray" }}>
          Manage it in Settings. Click the gear icon (upper right), go to "Settings" and choose which sportsbooks you want in My Sportsbooks.
        </Typography>
      </Container>
    );
  }
}

PicksTutorialPage.propTypes = {
};

export default PicksTutorialPage;
