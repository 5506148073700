import { analytics } from "../firebase";

import axios from "axios";

const subscription = {};

subscription.getProducts = () => {
  return new Promise((resolve, reject) => {
    axios(process.env.REACT_APP_API_ENDPOINT + "/products")
      .then(res => {
        analytics.logEvent("get_products");
        const data = res.data;
        resolve(data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

subscription.createCheckoutSession = (tier) => {
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/create-checkout-session", { "tier": tier })
      .then(res => {
        analytics.logEvent("create_checkout_session");
        const data = res.data;
        resolve(data.sessionId);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

subscription.createCustomerPortalSession = () => {
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/create-customer-portal-session")
      .then(res => {
        analytics.logEvent("create_customer_portal_session");
        const data = res.data;
        resolve(data.sessionURL);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

subscription.getPicks = (propsite) => {
  return new Promise((resolve, reject) => {
    const url = process.env.REACT_APP_API_ENDPOINT + "/picks";
    const data = {
      propsite: propsite,
    };
    axios.post(url, data)
      .then(res => {
        analytics.logEvent("get_picks");
        const data = res.data;
        resolve(data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

subscription.getPick = (id) => {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_API_ENDPOINT + `/pick`;
    axios.post(url, { id: id })
      .then(res => {
        analytics.logEvent("get_recent_picks");
        const data = res.data;
        resolve(data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

export default subscription;
