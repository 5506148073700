import React, { Component } from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import moment from "moment";

import { withStyles } from "@material-ui/core/styles";

import {
  Typography,
  Fab,
  Box
} from "@material-ui/core";

import BannerDialog from "../BannerDialog";
import SharePrompt from "../SharePrompt";

const styles = (theme) => ({
});

const initialState = {
  displayedBanners: JSON.parse(localStorage.getItem("displayedBanners")) || []
};

class BannersContainer extends Component {

  constructor(props) {
    super(props);

    this.state = initialState;
  }

  markBannerAsRead = (id) => {
    let displayedBanners = this.state.displayedBanners;
    displayedBanners.push(id);
    localStorage.displayedBanners = JSON.stringify(displayedBanners);
    this.setState({
      displayedBanners: displayedBanners
    })
  }

  referalBanner = (user, openSnackbar) => {
    const referText = "Refer a friend to HammerPicks (using your link)! If they become a subscriber you'll get 50% off your next payment!";
    return {
      id: `refer-a-friend-${moment().format("MM-YYYY")}`,
      content: <SharePrompt
        text={referText}
        copyText={`https://hammerpicks.com?referrerEmail=${user.email}`}
        openSnackbar={openSnackbar}
      />
    }
  }

  upgradeBanner = (user) => {
    const daysUntilSubscriptionEnds = moment.duration(moment(user.subscription_ends_at).diff(moment())).asDays();
    if (daysUntilSubscriptionEnds > 5 || user.subscription_tier === "year") {
      return null;
    }
    let upgradeText = "Save 44% off a weekly subscription by upgrading to yearly.";
    switch (user.subscription_tier) {
      case "week":
        upgradeText += " 30% off if you go with monthly."
        break;
      default:
        break;
    }

    return {
      id: `upgrade-subscription`,
      content: <>
        <Typography variant="h6" align="center">
          More bang for your buck
        </Typography>
        <Box p={2}>
          <Typography variant="body1" align="center">
            {upgradeText}
          </Typography>
        </Box>
        <Box p={2}>
          <Typography variant="body1" align="center">
            <Fab style={{ fontWeight: 700 }} variant="extended" color="secondary" component={Link} to="/upgrade">
              Upgrade
            </Fab>
          </Typography>
        </Box>
      </>
    }
  }

  picksTutorialBanner = (user, onPicksTutorialClicked) => {
    const daysSinceUserCreated = moment.duration(moment().diff(moment(user.created_at))).asDays();
    if (daysSinceUserCreated > 31) {
      return null;
    }
    return {
      id: `picks-tutorial`,
      content: <>
        <Typography variant="h5" align="center">
          Welcome to HammerPicks
        </Typography>
        <Box p={2}>
          <Typography variant="body1" align="center">
            Support is always just a click away, look for the orange icon in the bottom right!
          </Typography>
          <Typography variant="body1" align="center">
            Not sure what a hammer is? Bold means what? Check out the
          </Typography>
        </Box>
        <Box p={2}>
          <Typography variant="body1" align="center">
            <Fab align="center" style={{ fontWeight: 700 }} variant="extended" color="secondary" onClick={() => onPicksTutorialClicked()}>
              Picks Tutorial
            </Fab>
          </Typography>
        </Box>
      </>
    }
  }

  render() {
    const {
      user,
      propsite,
      banners,
      onPicksTutorialClicked,
      openSnackbar
    } = this.props;

    if (!user || !user.subscription_ends_at) {
      return <></>
    }

    const query = Object.fromEntries(new URLSearchParams(window.location.search));
    const disableBanners = query["hide"] && query["hide"].split(",").includes("banners");
    if (disableBanners) {
      return <></>
    }

    const { displayedBanners } = this.state;

    const propsiteBanners = banners.filter(banner => {
      if (banner.data.propsites && banner.data.propsites.length > 0) {
        return banner.data.propsites.indexOf(propsite) !== -1;
      }
      return true;
    });

    let hardcodedBanners = [
      this.referalBanner(user, openSnackbar),
      this.upgradeBanner(user),
      this.picksTutorialBanner(user, onPicksTutorialClicked)
    ];
    return (
      <>
        {propsiteBanners.map(banner => {
          return (
            <BannerDialog
              key={banner.id}
              id={banner.id}
              textColor={banner.data.textColor}
              backgroundColor={banner.data.backgroundColor}
              content={
                <>
                  <Box p={1}>
                    <Typography variant="h5" align="center">
                      {banner.data.title}
                    </Typography>
                  </Box>
                  <Box p={1}>
                    {banner.data.text.split("LINEBREAK").map(text => {
                      return (
                        <Typography variant="body1" align="center">
                          {text}
                        </Typography>
                      )
                    })}
                  </Box>
                  <Box p={1}>
                    {banner.data.link && banner.data.linkTitle && banner.data.linkExternal &&
                      <Typography variant="body1" align="center">
                        <Fab style={{ fontWeight: 700 }} variant="extended" color="primary" href={banner.data.link}>
                          {banner.data.linkTitle}
                        </Fab>
                      </Typography>
                    }
                    {banner.data.link && banner.data.linkTitle && !banner.data.linkExternal &&
                      <Typography variant="body1" align="center">
                        <Fab style={{ fontWeight: 700 }} variant="extended" color="primary" component={Link} to={banner.data.link}>
                          {banner.data.linkTitle}
                        </Fab>
                      </Typography>
                    }
                  </Box>
                </>
              }
              displayedBanners={displayedBanners}
              onCloseBanner={() => this.markBannerAsRead(banner.id)}
            />
          )
        })}
        {hardcodedBanners.map(banner => {
          if (!banner) {
            return null;
          }
          return (
            <BannerDialog
              key={banner.id}
              id={banner.id}
              content={banner.content}
              displayedBanners={displayedBanners}
              onCloseBanner={() => this.markBannerAsRead(banner.id)}
            />
          )
        })}
      </>
    );
  }
}

BannersContainer.propTypes = {
  user: PropTypes.object,
  banners: PropTypes.array.isRequired,
  propsite: PropTypes.string.isRequired,
  openSnackbar: PropTypes.func.isRequired,
  showHardcodedBanners: PropTypes.bool.isRequired,
  onPicksTutorialClicked: PropTypes.func.isRequired,
};

export default withStyles(styles)(BannersContainer);
