import React from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { Close as CloseIcon } from "@material-ui/icons";

import PicksTutorialPage from "../PicksTutorialPage";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

function PicksTutorialDialog(props) {
  const classes = useStyles();
  const dialogProps = props.dialogProps;

  return (
    <Dialog fullWidth maxWidth="md" {...dialogProps}>
      <DialogTitle disableTypography>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          Picks Tutorial
        </Typography>

        <Tooltip title="Close">
          <IconButton
            className={classes.closeButton}
            onClick={dialogProps.onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <PicksTutorialPage />
      </DialogContent>
    </Dialog>
  );
}

PicksTutorialDialog.propTypes = {
  dialogProps: PropTypes.object.isRequired,
};

export default PicksTutorialDialog;
