import React, { Component } from "react";

import PropTypes from "prop-types";

import validate from "validate.js";

import { withStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

import constraints from "../../data/constraints";
import authentication from "../../services/authentication";

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },

  divider: {
    margin: "auto",
  },

  grid: {
    marginBottom: theme.spacing(2),
  },
});

const initialState = {
  performingAction: false,
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  passwordConfirmation: "",
  promoCode: "",
  referrerEmail: "",
  source: "n/a",
  errors: null,
};

if (process.env.NODE_ENV === "development") {
  initialState.firstName = "First";
  initialState.lastName = "Last";
  initialState.email = "hammerpicks88@gmail.com";
  initialState.password = "test123";
  initialState.passwordConfirmation = "test123";
}

class SignUpDialog extends Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    const referrerEmail = urlParams.get("referrerEmail");
    if (referrerEmail && referrerEmail.length) {
      initialState.referrerEmail = referrerEmail;
    }
    this.state = initialState;
  }

  signUp = () => {
    const {
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
      promoCode,
      referrerEmail,
      source
    } = this.state;

    const errors = validate(
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        passwordConfirmation: passwordConfirmation,
      },
      {
        firstName: constraints.firstName,
        lastName: constraints.lastName,
        email: constraints.email,
        password: constraints.password,
        passwordConfirmation: constraints.passwordConfirmation,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          performingAction: true,
          errors: null,
        },
        () => {
          authentication
            .signUp(firstName, lastName, email, password, promoCode, referrerEmail, source)
            .then((value) => {
              this.props.dialogProps.onClose();
            })
            .catch((error) => {
              this.props.openSnackbar(error);
            })
            .finally(() => {
              this.setState({
                performingAction: false,
              });
            });
        }
      );
    }
  };

  handleKeyPress = (event) => {
    const {
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation
    } = this.state;

    if (
      !firstName ||
      !lastName ||
      !email ||
      !password ||
      !passwordConfirmation
    ) {
      return;
    }

    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === "Enter") {
      this.signUp();
    }
  };

  handleExited = () => {
    this.setState(initialState);
  };

  handleFirstNameChange = (event) => {
    const firstName = event.target.value;

    this.setState({
      firstName: firstName,
    });
  };

  handleLastNameChange = (event) => {
    const lastName = event.target.value;

    this.setState({
      lastName: lastName,
    });
  };

  handleEmailChange = (event) => {
    const email = event.target.value;

    this.setState({
      email: email,
    });
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;

    this.setState({
      password: password,
    });
  };

  handlePasswordConfirmationChange = (event) => {
    const passwordConfirmation = event.target.value;

    this.setState({
      passwordConfirmation: passwordConfirmation,
    });
  };

  handlePromoCodeChange = (event) => {
    const promoCode = event.target.value;

    this.setState({
      promoCode: promoCode,
    });
  };

  handleReferrerEmailChange = (event) => {
    const referrerEmail = event.target.value;

    this.setState({
      referrerEmail: referrerEmail,
    });
  };

  handleSourceChange = (event) => {
    const source = event.target.value;

    this.setState({
      source: source,
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Dialog Properties
    const { dialogProps } = this.props;

    const {
      performingAction,
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
      promoCode,
      referrerEmail,
      source,
      errors,
    } = this.state;

    const allSources = process.env.REACT_APP_SOURCES.split(",");

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        disableBackdropClick={performingAction}
        disableEscapeKeyDown={performingAction}
        {...dialogProps}
        onKeyPress={this.handleKeyPress}
        onExited={this.handleExited}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6">Sign up for an account</Typography>

          <Tooltip title="Close">
            <IconButton
              className={classes.closeButton}
              disabled={performingAction}
              onClick={dialogProps.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item xs key={"firstName"}>
              <TextField
                autoComplete="firstName"
                disabled={performingAction}
                error={!!(errors && errors.firstName)}
                fullWidth
                helperText={
                  errors && errors.firstName
                    ? errors.firstName[0]
                    : ""
                }
                label="First Name"
                placeholder="John"
                required
                type="text"
                value={firstName}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={this.handleFirstNameChange}
              />
            </Grid>
            <Grid item xs key={"lastName"}>
              <TextField
                autoComplete="lastName"
                disabled={performingAction}
                error={!!(errors && errors.lastName)}
                fullWidth
                helperText={
                  errors && errors.lastName
                    ? errors.lastName[0]
                    : ""
                }
                label="Last Name"
                placeholder="Doe"
                required
                type="text"
                value={lastName}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={this.handleLastNameChange}
              />
            </Grid>
            <Grid item xs key={"email"}>
              <TextField
                autoComplete="email"
                disabled={performingAction}
                error={!!(errors && errors.email)}
                fullWidth
                helperText={
                  errors && errors.email ? errors.email[0] : ""
                }
                inputProps={{
                  autoCapitalize: 'none',
                }}
                label="E-mail address"
                placeholder="john@doe.com"
                required
                type="emailAddress"
                value={email}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={this.handleEmailChange}
              />
            </Grid>
            <Grid item xs key={"password"}>
              <TextField
                autoComplete="password"
                disabled={performingAction}
                error={!!(errors && errors.password)}
                fullWidth
                helperText={
                  errors && errors.password ? errors.password[0] : ""
                }
                label="Password"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                required
                type="password"
                value={password}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={this.handlePasswordChange}
              />
            </Grid>
            <Grid item xs key={"passwordConfirmation"}>
              <TextField
                autoComplete="password"
                disabled={performingAction}
                error={!!(errors && errors.passwordConfirmation)}
                fullWidth
                helperText={
                  errors && errors.passwordConfirmation
                    ? errors.passwordConfirmation[0]
                    : ""
                }
                label="Password confirmation"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                required
                type="password"
                value={passwordConfirmation}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={this.handlePasswordConfirmationChange}
              />
            </Grid>
            {
              <Grid item xs key={"promoCode"}>
                <TextField
                  autoComplete="promo-code"
                  disabled={performingAction}
                  error={!!(errors && errors.promoCode)}
                  fullWidth
                  helperText={
                    errors && errors.promoCode
                      ? errors.promoCode[0]
                      : ""
                  }
                  label="Promo code"
                  placeholder="Promo code"
                  required
                  type="text"
                  value={promoCode}
                  variant="outlined"
                  InputLabelProps={{ required: false }}
                  onChange={this.handlePromoCodeChange}
                />
              </Grid>
            }
            <Grid item xs key={"source"}>
              <Typography variant="body1">
                How did you find out about HammerPicks?
              </Typography>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Source</InputLabel>
                <Select
                  labelId="sort-select-outlined-label"
                  id="sort-select-outlined"
                  value={source}
                  onChange={this.handleSourceChange}
                  label="Source"
                >
                  {allSources.map(s => {
                    return <MenuItem value={s.toLowerCase()}>{s}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            {referrerEmail && referrerEmail.length &&
              <Grid item xs key={"referrerEmail"}>
                <Typography>
                  {`Referrer: ${referrerEmail}`}
                </Typography>
              </Grid>
            }
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            disabled={
              !firstName ||
              !lastName ||
              !email ||
              !password ||
              !passwordConfirmation ||
              performingAction
            }
            variant="contained"
            onClick={this.signUp}
          >
            Sign up
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SignUpDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignUpDialog);
