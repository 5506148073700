const social = {};

const emojis = {
  "NBA": "🏀",
  "CBB": "🏀",
  "NFL": "🏈",
  "NFLSZN": "🏈",
  "CFB": "🏈",
  "TENNIS": "🎾",
  "NHL": "🏒",
  "PGA": "⛳️",
  "MLB": "⚾"
};

const hashtags = {
  "NBA": "#nba #fantasybasketball #nbapicks",
  "CBB": "#collegebasketball #fantasybasketball #cbb",
  "NFL": "#nfl #parlay #fantasyfootball",
  "NFLSZN": "#nfl #parlay #fantasyfootball",
  "CFB": "#collegefootball #fantasyfootball",
  "TENNIS": "#tennis #tennisbetting",
  "NHL": "#fantasyhockey #nhl #nhlpicks",
  "PGA": "#pgatour #pga #golfbetting #golf",
  "MLB": "#mlb #baseballbetting #baseball"
};

social.socialFor = (league, propsite) => {
  const leagueHashtags = hashtags[league] || "";
  const propsiteHastags = `#${propsite} #${propsite}${league}`;
  return {
    emoji: emojis[league] || "",
    hashtags: `${propsiteHastags} ${leagueHashtags} #dfs #sportsbetting #parlay #betting`
  }
};

social.winHashtags = (propsite) => {
  return `#${propsite} #hammers #winners`;
}

export default social;
