import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Container,
  Typography
} from "@material-ui/core";

import util from "../../services/util";

class AboutPage extends Component {

  render() {
    let { propsites } = this.props;
    propsites = util.propsitesCapitalizedXPrizePicks(propsites);
    return (
      <Container>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          About HammerPicks
        </Typography>
        <Typography variant="body1">
          HammerPicks is a real-time feed of PrizePicks* props that signal a clear Vegas edge. We find the picks, all you have to do is Hammer them.
          <br />
          <ul>
            <li>A curated (and customizable) list of favorable prop picks</li>
            <li>Optional push/email alerts for updated lines and picks</li>
            <li>New leagues and props added all the time</li>
          </ul>
          <br />
          *We also support {propsites.join(", ")}
        </Typography>
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          <br />
          More
        </Typography>
        <Typography variant="body1">
          HammerPicks is not affiliated with or endorsed by Apple, the NFL, MLB, NBA, NHL, PGA, NCAA, or any sports association. HammerPicks is not a gambling site and does not accept wagers. If you or someone you know has a gambling problem and wants help, call 1-800-GAMBLER.
          <br />
          Terms of Use: https://hammerpicks.com/terms
          <br />
          Privacy Policy: https://hammerpicks.com/privacy
        </Typography>
      </Container>
    );
  }

}

AboutPage.propTypes = {
  propsites: PropTypes.array.isRequired,
};

export default AboutPage;
