import React, { Component } from "react";

import { withStyles } from '@material-ui/core/styles';

import PropTypes from "prop-types";

import { Link as RouterLink } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Box,
  ButtonGroup,
  Button,
  IconButton,
  Divider,
  Menu,
  MenuItem,
  Link,
  Hidden
} from "@material-ui/core";

import { Settings as SettingsIcon } from "@material-ui/icons";

const styles = (theme) => ({
  logo: {
    height: '50px',
    width: '250px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  logoXSmall: {
    height: '34px',
    width: '170px',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
});

class Bar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        anchorEl: null,
      },
    };
  }

  openMenu = (event) => {
    const anchorEl = event.currentTarget;

    this.setState({
      menu: {
        anchorEl,
      },
    });
  };

  closeMenu = () => {
    this.setState({
      menu: {
        anchorEl: null,
      },
    });
  };

  render() {
    const {
      performingAction,
      user,
      onAboutClick,
      onFAQClick,
      onSettingsClick,
      onSignOutClick,
      onSignUpClick,
      onSignInClick,
      onPicksTutorialClick,
      classes
    } = this.props;

    const { menu } = this.state;

    const query = Object.fromEntries(new URLSearchParams(window.location.search));
    const disableBar = query["hide"] && query["hide"].split(",").includes("bar");
    if (disableBar) {
      return <></>
    }

    const menuItems = [
      {
        name: "About",
        onClick: onAboutClick,
      },
      {
        name: "Settings",
        onClick: onSettingsClick,
      },
      {
        name: "Picks Tutorial",
        onClick: onPicksTutorialClick
      },
      {
        name: "FAQ",
        onClick: onFAQClick,
      },
      {
        name: "Sign out",
        divide: true,
        onClick: onSignOutClick,
      },
    ];

    return (
      <AppBar color="primary" position="static">
        <Toolbar>
          <Hidden xsDown>
            <Link component={RouterLink} to="/">
              <img src={"/logo.svg"} className={classes.logo} alt="logo" />
            </Link>
          </Hidden>
          <Hidden smUp>
            <Link component={RouterLink} to="/">
              <img src={"/logo.svg"} className={classes.logoXSmall} alt="logo" />
            </Link>
          </Hidden>
          <Box display="flex" flexGrow={1}>
          </Box>

          {user && (
            <>
              <IconButton
                color="inherit"
                disabled={performingAction}
                onClick={this.openMenu}
              >
                <SettingsIcon />
              </IconButton>

              <Menu
                anchorEl={menu.anchorEl}
                open={Boolean(menu.anchorEl)}
                onClose={this.closeMenu}
              >
                {menuItems.map((menuItem, index) => {
                  if (
                    menuItem.hasOwnProperty("condition") &&
                    !menuItem.condition
                  ) {
                    return null;
                  }

                  let component = null;

                  if (menuItem.to) {
                    component = (
                      <MenuItem
                        key={index}
                        component={RouterLink}
                        to={menuItem.to}
                        onClick={this.closeMenu}
                      >
                        {menuItem.name}
                      </MenuItem>
                    );
                  } else {
                    component = (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          this.closeMenu();

                          menuItem.onClick();
                        }}
                      >
                        {menuItem.name}
                      </MenuItem>
                    );
                  }

                  if (menuItem.divide) {
                    return (
                      <span key={index}>
                        <Divider />

                        {component}
                      </span>
                    );
                  }

                  return component;
                })}
              </Menu>
            </>
          )}

          {!user && (
            <ButtonGroup
              color="inherit"
              disabled={performingAction}
              variant="outlined"
            >
              <Button onClick={onSignUpClick}>Sign up</Button>
              <Button onClick={onSignInClick}>Sign in</Button>
            </ButtonGroup>
          )}
        </Toolbar>
      </AppBar >
    );
  }
}

Bar.defaultProps = {
  performingAction: false,
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onFAQClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(Bar);
