import ReactGA from 'react-ga4';

let googleAnalytics = {};

googleAnalytics.initGA = trackingID => {
    ReactGA.initialize(trackingID);
};

googleAnalytics.logPageView = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
};

googleAnalytics.logModalView = (title) => {
    ReactGA.send({ hitType: "pageview", page: title });
};

googleAnalytics.logEvent = (category, action, label) => {
    ReactGA.event({
        category,
        action,
        label
    });
};

export default googleAnalytics;