import React, { Component } from "react";

import PropTypes from "prop-types";

import moment from "moment";

import {
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Hidden,
  ButtonGroup,
  Button,
  Typography
} from "@material-ui/core";

import {
  CardMembership as CardMembershipIcon,
  MeetingRoom as MeetingRoomIcon
} from "@material-ui/icons";

import CheckoutTiers from "../CheckoutTiers";
import SubscriptionUpgrade from "../SubscriptionUpgrade";

const initialState = {
  performingAction: false,
};

class SubscriptionTab extends Component {


  constructor(props) {
    super(props);

    this.state = initialState;
  }

  render() {
    const { user, onCheckoutClicked, onCustomerPortalClicked, openSnackbar } = this.props;

    const hasActiveSubscription = user.subscription_ends_at && moment.utc(user.subscription_ends_at) > new Date();
    const hasActiveCancellation = hasActiveSubscription && user.subscription_cancelled_at;

    return (
      <DialogContent>
        <List disablePadding>
          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <CardMembershipIcon />
              </ListItemIcon>
            </Hidden>

            <ListItemText
              primary="Subscription"
              secondary={
                hasActiveSubscription
                  ? `Active subscription expires ${moment.utc(user.subscription_ends_at).format("LLL")}`
                  : "Inactive subscription"
              }
            />
          </ListItem>
          {(!hasActiveSubscription || hasActiveCancellation) && user.subscription_platform === "stripe" &&
            <CheckoutTiers
              title="Welcome back"
              description="Subscribe today to access the Picks"
              user={user}
              disabledTiers={["week"]}
              highlightedTiers={["6-month", "year"]}
              onCheckoutClicked={(tier) => onCheckoutClicked(tier)}
              openSnackbar={openSnackbar}
            />
          }
          {hasActiveSubscription && !hasActiveCancellation && user.subscription_platform === "stripe" &&
            <>
              <ListItem>
                <Hidden xsDown>
                  <ListItemIcon>
                    <MeetingRoomIcon />
                  </ListItemIcon>
                </Hidden>

                <ListItemText
                  primary="Customer Portal"
                  secondary={`Manage Your Subscription (${user.subscription_tier})`}
                />
                <ListItemSecondaryAction>
                  <ButtonGroup
                    color="secondary"
                    variant="outlined"
                  >
                    <Button onClick={() => onCustomerPortalClicked(true)}>Cancel</Button>
                    <Button onClick={() => onCustomerPortalClicked(false)}>Manage</Button>
                  </ButtonGroup>
                </ListItemSecondaryAction>
              </ListItem>
              {hasActiveSubscription && user.subscription_status_next_payment &&
                <Typography variant="caption" style={{ "fontStyle": "italic" }}>
                  {user.subscription_status_next_payment}
                </Typography>
              }
              {!["year", "lifetime"].includes(user.subscription_tier) &&
                <SubscriptionUpgrade
                  user={user}
                  openSnackbar={this.props.openSnackbar}
                />
              }
            </>
          }
          {hasActiveSubscription && user.subscription_platform === "iOS" &&
            <>
              <ListItem>
                <Hidden xsDown>
                  <ListItemIcon>
                    <MeetingRoomIcon />
                  </ListItemIcon>
                </Hidden>

                <ListItemText
                  primary="Customer Portal"
                  secondary={`Manage Your Subscription (${user.subscription_tier}) in the Settings app`}
                />
              </ListItem>
            </>
          }
        </List>
      </DialogContent>
    );
  }
}

SubscriptionTab.propTypes = {
  user: PropTypes.object,
  openSnackbar: PropTypes.func.isRequired,
  onCustomerPortalClicked: PropTypes.func.isRequired,
  onCheckoutClicked: PropTypes.func.isRequired,
};

export default SubscriptionTab;
