
import React, { Component } from "react";

import PropTypes from "prop-types";

import withWidth from '@material-ui/core/withWidth';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Tooltip,
  IconButton,
  Button,
  Chip
} from "@material-ui/core";

import EmptyState from "../EmptyState";
import LaunchScreen from "../LaunchScreen";
import MultiSelectField from "../MultiSelectField";
import TwitterFollowPrompt from "../TwitterFollowPrompt";
import BannersContainer from "../BannersContainer";

import subscription from "../../services/subscription";
import googleAnalytics from "../../services/googleAnalytics";
import admin from "../../services/admin";

import moment from "moment-timezone";

import images from "../../services/images";
import social from "../../services/social";
import util from "../../services/util";
import { firestore } from '../../firebase';

import {
  ValueAxis,
  Chart,
  BarSeries,
  LineSeries
} from '@devexpress/dx-react-chart-material-ui';

const initialState = {
  ready: false,
  picks: [],
  selectedPicks: [],
  leagues: [],
  selectedLeagues: [],
  sort: "SCORE",
  expandedPicks: {},
  recentPicks: {},
  propsite: "prizepicks",
  startsAt: "ANY",
  banners: [],
  message: ""
};

class PicksPage extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
    if (props.user) {
      this.state.sort = props.user.picks_sort;
      if (props.user.propsites && props.user.propsites.length > 0) {
        this.state["propsite"] = props.user.propsites[0];
      }
    }
  }

  publicPickMessage = (pick) => {
    const date = moment().format("M/D/YY");
    const socials = social.socialFor(pick.league, pick.propsite);
    let message = `${date} ${pick.league} Pick is ${pick.name} ${pick.choice === "OVER" ? "o" : "u"}${pick.ps_line_value} ${pick.prop} ${socials.emoji} 🔥`;
    message += `\n\nSubscribe at 👉 HammerPicks.com`;
    message += `\n\n${socials.hashtags}`;
    return message;
  }

  addPublicPick = (pick) => {
    if (window.confirm(`Make ${pick.id} a public pick?`)) {
      admin.addPublicPick(pick)
        .then((data) => {
          navigator.clipboard.writeText(this.publicPickMessage(pick));
          this.props.openSnackbar("Added public pick");
        })
        .catch((error) => {
          this.props.openSnackbar(error);
        })
    }
  }

  onSortScore = (event) => {
    if (!event) { return; }

    const isEnabled = event.target.checked;
    this.setState({
      sort: isEnabled ? "SCORE" : "SCORE"
    }, function () {
      this.filterPicks();
    });
  }

  onSortOdds = (event) => {
    if (!event) { return; }

    const isEnabled = event.target.checked;
    this.setState({
      sort: isEnabled ? "ODDS" : "SCORE"
    }, function () {
      this.filterPicks();
    });
  }

  onSortLineDifference = (event) => {
    if (!event) { return; }

    const isEnabled = event.target.checked;
    this.setState({
      sort: isEnabled ? "LINE_DIFFERENCE" : "SCORE"
    }, function () {
      this.filterPicks();
    });
  }

  onUpdateSort = (event) => {
    const value = event.target.value;
    this.setState({
      sort: value
    }, function () {
      this.filterPicks();
    });
  };

  onUpdateLeagues = (l) => {
    const {
      leagues
    } = this.state;
    let selectedLeagues = l
    if (l.length === 0) {
      selectedLeagues = leagues;
    }
    this.setState({
      selectedLeagues: selectedLeagues
    }, function () {
      this.filterPicks();
    });
  };

  onUpdatePropsite = (event) => {
    if (!event) { return; }

    this.setState({
      ready: false,
      picks: [],
      selectedPicks: [],
      propsite: event.target.value,
      expandedPicks: []
    }, function () {
      this.getPicks(true);
    });
  };

  onUpdateStartsAt = (event) => {
    if (!event) { return; }

    this.setState({
      startsAt: event.target.value,
    }, function () {
      this.filterPicks();
    });
  };

  filterPicks() {
    const {
      sort,
      startsAt,
      picks,
      selectedLeagues
    } = this.state;
    let selectedPicks = picks.filter(pick => {
      const l = selectedLeagues.includes(pick.league);
      return l;
    });
    switch (startsAt) {
      case "TODAY":
        selectedPicks = selectedPicks.filter(pick => {
          let startsAt = moment.utc(pick.starts_at).local();
          return (startsAt.format("YYYYMMDD") === moment.utc(new Date()).local().format("YYYYMMDD"));
        });
        break;
      default:
        break;
    }
    selectedPicks = selectedPicks.sort((a, b) => {
      if (sort === "LINE_DIFFERENCE") {
        return Math.abs(b.line_value_difference) - Math.abs(a.line_value_difference);
      } else if (sort === "ODDS") {
        const b_value = b.choice === "UNDER" ? b.sb_under : b.sb_over;
        const a_value = a.choice === "UNDER" ? a.sb_under : a.sb_over;
        return -1 * b_value - -1 * a_value;
      } else {
        return b.score - a.score;
      }
    });
    this.setState({
      selectedPicks: selectedPicks
    });
  };

  handlePicksSportsbookInfo = (pick) => {
    let { expandedPicks } = this.state;
    expandedPicks[pick.id] = expandedPicks[pick.id] ? false : true;
    this.getRecentPicks(pick);
    this.setState({
      expandedPicks: expandedPicks
    })
  }

  showRecentChartBetaMessage = () => {
    window.alert("This feature is in beta, some data may be missing and/or incomplete.")
  }

  showExternalURL = () => {
    const { propsite, picks, expandedPicks } = this.state;
    switch (propsite) {
      case "prizepicks":
        const ids = Object.keys(expandedPicks).filter(id => {
          return expandedPicks[id] === true;
        });
        if (ids.length === 0) {
          window.alert("Please select at least one pick.")
          return;
        }
        const actualPicks = picks.filter(pick => {
          return ids.includes(pick.id);
        });
        const endings = actualPicks.map(pick => {
          const fixedLine = `${pick.ps_line_value}`.replace(".", "p");
          return `projId=${pick.ps_id}-${pick.choice === "OVER" ? "o" : "u"}-${fixedLine}`;
        });
        const url = `https://app.prizepicks.com/board/${endings.join('')}`;
        window.open(url, "_blank");
        break;
      default:
        break;
    }
  }

  capitalize = (string) => {
    if (string && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    } else {
      return string;
    }
  }

  startsAtFormatted = (pick) => {
    let startsAt = moment.utc(pick.starts_at).local();
    let startsAtFormatted = startsAt.format("LT")
    if (startsAt.format("YYYYMMDD") !== moment.utc(new Date()).local().format("YYYYMMDD")) {
      if (moment.duration(startsAt - moment()).asDays() < 7) {
        startsAtFormatted = startsAt.format("ddd h:mm A");
      } else {
        startsAtFormatted = startsAt.format("M/D h:mm A");
      }
    }
    return startsAtFormatted;
  }

  truncateText = (string, maxLength = 26) => {
    return string.length > maxLength ? string.substring(0, maxLength - 3) + "..." : string;
  }

  sportsbookShortName = (sportsbook) => {
    switch (sportsbook) {
      case "fanduel":
        return "fd";
      case "draftkings":
        return "dk";
      case "caesars":
        return "c";
      case "pinnacle":
        return "p";
      case "betmgm":
        return "mgm";
      case "pointsbet":
        return "pb";
      case "fliff":
        return "fl";
      case "espnbet":
        return "espn";
      case "betrivers":
        return "rivers";
      case "betus":
        return "us";
      case "fanatics":
        return "fan";
      default:
        return null;
    }
  }

  propsiteShortName = (propsite) => {
    switch (propsite) {
      case "prizepicks":
        return "pp";
      case "nohouseadvantage":
        return "nha";
      case "thrive":
        return "thr";
      case "underdog":
        return "ud";
      default:
        return null;
    }
  }

  sportsbookIcon = (sportsbook) => {
    return (
      <Tooltip title={sportsbook} style={{ marginBottom: "4px", marginLeft: "4px" }}>
        <IconButton size="small">
          <img src={`./images/favicon_${sportsbook}.png`} style={{ width: "14px", height: "14px" }} alt="" />
        </IconButton>
      </Tooltip>
    )
  }

  sportsbookInfo = (pick) => {
    return (
      <div style={{ color: "gray" }}>
        {
          pick.scores.map(score => {
            if (score.sportsbook === pick.sportsbook) {
              return <></>
            }
            let texts = [
              `${Math.min(10.0, score.score)}`,
              `${score.sb_line_value} ${score.sb_over}o ${score.sb_under}u`
            ];
            return (
              <div>
                <b>{score.emojis}</b>
                {texts.join(" | ")}
                {this.sportsbookIcon(score.sportsbook)}
              </div>
            )
          })
        }
      </div>
    )
  }

  testRecentPicksChart = (pick) => {
    const chartData = [
      { index: 0, hit: 12, miss: 0, push: 0, line: 10 },
      { index: 1, hit: 23, miss: 0, push: 0, line: 10 },
      { index: 2, hit: 0, miss: 0, push: 10, line: 10 },
      { index: 3, hit: 0, miss: 6, push: 0, line: 10 },
      { index: 4, hit: 14, miss: 0, push: 0, line: 10 },
      { index: 5, hit: 15, miss: 0, push: 0, line: 10 },
      { index: 6, hit: 0, miss: 8, push: 0, line: 10 },
      { index: 7, hit: 0, miss: 0, push: 10, line: 10 },
      { index: 8, hit: 16, miss: 0, push: 0, line: 10 },
      { index: 9, hit: 16, miss: 0, push: 0, line: 10 },
    ];
    return this.generateRecenPicksChart(pick.id, chartData, { hits: 6, misses: 2, pushes: 2, average: 9.5 });
  }

  recentPicksChart = (pick) => {
    const recentPicks = this.state.recentPicks[pick.id];
    if (!recentPicks) {
      return (
        <>
          <br />
          <Typography variant="caption">Loading...</Typography>
        </>
      )
    }
    const nonNulls = recentPicks.recent_picks.filter(recentPick => {
      return recentPick.outcome_value !== null;
    });
    if (nonNulls.length < 5) {
      return (
        <>
          <br />
          <Typography variant="caption">No recent data</Typography>
        </>
      )
    }

    const line = pick.ps_line_value;
    let hits = 0;
    let misses = 0;
    let pushes = 0;
    let sum = 0;
    const chartData = recentPicks.recent_picks.map((recentPick, i) => {
      let hit = 0;
      let miss = 0;
      let push = 0;
      const value = recentPick.outcome_value;
      if (value !== null) {
        if (value > line) {
          hit = value;
          hits += 1;
        } else if (value < line) {
          miss = value;
          misses += 1;
        } else {
          push = value;
          pushes += 1;
        }
        sum += value;
      }
      return { "index": i, "hit": hit, "miss": miss, "push": push, "line": line };
    });
    const average = sum / (hits + misses + pushes);
    return this.generateRecenPicksChart(pick.id, chartData, { hits: hits, misses: misses, pushes: pushes, average: average.toFixed(1) });
  }

  ticksFor = (max, min) => {
    return [min, (max + min) / 2, max];
  };


  generateRecenPicksChart = (id, chartData, resultsData) => {
    const width = 5 * 10 * 3;
    const height = 144;
    return (
      <Paper style={{ width: width + 8, height: height + 30 }}>
        <Chart
          id={`${id}-recent-chart`}
          height={height}
          width={width}
          data={chartData}
        >
          <ValueAxis tickSize={0} showTicks={false} showGrid={false} showLine={false} />
          <BarSeries
            valueField="hit"
            argumentField="index"
            color="#142657"
            width={5}
          />
          <BarSeries
            valueField="miss"
            argumentField="index"
            color="#F08A20"
            width={5}
          />
          <BarSeries
            valueField="push"
            argumentField="index"
            color="lightgray"
            width={5}
          />
          <LineSeries
            valueField="line"
            argumentField="index"
            color="gray"
          />
        </Chart>
        <Typography variant="caption" align="center">
          <Chip label={`AVG: ${resultsData.average}`} size="small" style={{ marginLeft: "4px", marginRight: "4px" }} />
          <span style={{ color: "#142657" }}>{resultsData.hits}↑</span> <span style={{ color: "#F08A20" }}>{resultsData.misses}↓</span> <span style={{ color: "gray" }}>{resultsData.pushes}</span>
          <Button size="small" variant="outlined" style={{ marginLeft: "4px", padding: "2px", minWidth: "0px", fontSize: "0.5rem", color: "gray" }} onClick={() => this.showRecentChartBetaMessage()}>
            BETA
          </Button>
        </Typography>
      </Paper>
    );
  }

  desktopTableRow = (pick, showSportsbookInfo, showPublicIcon, showOutliers, showRecentChart, isExpanded) => {
    let sportsbookInfo = <div></div>;
    if (showSportsbookInfo) {
      sportsbookInfo = this.sportsbookInfo(pick);
    }
    const style = { fontWeight: pick.is_new ? 600 : 400, padding: "4px" };
    return (
      <TableRow
        key={`${pick.id}`}
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          cursor: "pointer"
        }}
        onClick={() => this.handlePicksSportsbookInfo(pick)}
      >
        <TableCell component="th" scope="row" align="right" style={{ border: "none", fontSize: "0.5rem" }}>
          {pick.hammers}
        </TableCell>
        <TableCell align="left" style={style}>
          {pick.name}
        </TableCell>
        <TableCell align="left" style={style}>{pick.prop}</TableCell>
        <TableCell align="left" style={style}>{`${pick.choice === "OVER" ? "o" : "u"}${pick.ps_line_value}`}{pick.is_taco ? " 🌮" : ""}</TableCell>
        <TableCell align="center" style={style}>{Math.min(10.0, pick.score)}</TableCell>
        <TableCell align="right" style={style}>
          {`${(pick.is_outlier && showOutliers && "❌") || ""}${pick.sb_line_value} ${pick.sb_over}o ${pick.sb_under}u`}
          {this.sportsbookIcon(pick.sportsbook)}
          {sportsbookInfo}
        </TableCell>
        <TableCell align="center" style={style}>{`${pick.line_value_difference !== 0 ? `${pick.line_value_difference}` : ""}`}</TableCell>
        <TableCell align="left" style={style}>
          {!isExpanded &&
            <Button size="small" variant="outlined" style={{ marginRight: "4px", minWidth: "0px" }}>
              📊
            </Button>
          }
          {this.truncateText(`${this.startsAtFormatted(pick)} ${this.truncateText(pick.versus, 15)}`, 44)}
          {showRecentChart && this.recentPicksChart(pick)}
        </TableCell>
        <TableCell align="center" style={style}>{pick.league}</TableCell>
        <TableCell style={style}>
          {showPublicIcon &&
            <Button size="small" variant="outlined" onClick={() => this.addPublicPick(pick)}>
              SET PUBLIC 🚀
            </Button>
          }
          {isExpanded &&
            <span style={{ color: "#F08A20" }}>
              ✓
            </span>
          }
        </TableCell>
      </TableRow>
    )
  };

  mobileTableRow = (pick, showOutliers, showSportsbook, showRecentChart, isExpanded) => {
    let sportsbookInfo = <div></div>;
    if (showSportsbook) {
      sportsbookInfo = this.sportsbookInfo(pick);
    }
    const style = { fontWeight: pick.is_new ? 600 : 400, padding: "4px" };
    return (
      <TableRow
        key={`${pick.id}`}
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        onClick={() => this.handlePicksSportsbookInfo(pick)}
      >
        <TableCell align="left" style={{ width: "10px", border: "none", padding: "0px", paddingTop: "4px", paddingRight: "4px", fontSize: "0.5rem" }}>
          {Array.from(pick.hammers).map(char => {
            return (
              <>
                {char}
                <br />
              </>
            )
          })}
        </TableCell>
        <TableCell component="th" scope="row" style={style}>
          {this.truncateText(`${Math.min(10.0, pick.score)} - ${pick.name}`)}<br />
          {pick.prop} {pick.choice === "OVER" ? "o" : "u"}{pick.ps_line_value}
          <span style={{ color: "gray" }}>{pick.line_value_difference ? ` (${pick.line_value_difference})` : ""}</span>
          {pick.is_taco ? " 🌮" : ""}
          {showRecentChart && this.recentPicksChart(pick)}
        </TableCell>
        <TableCell align="right" style={style}>
          {`${(pick.is_outlier && showOutliers && "❌") || ""}${pick.sb_line_value} ${pick.sb_over}o ${pick.sb_under}u`}
          <span style={{ color: "gray" }}>
            {this.sportsbookIcon(pick.sportsbook)}
            {sportsbookInfo}
          </span>
          <span style={{ color: "gray", fontSize: "0.6rem", verticalAlign: "top" }}>
            {this.truncateText(`${this.truncateText(pick.versus, 15)} (${pick.league}) ${this.startsAtFormatted(pick)}`, 44)}
          </span>
        </TableCell>
        <TableCell style={style}>
          {isExpanded &&
            <span style={{ color: "#F08A20" }}>
              ✓
            </span>
          }
        </TableCell>
      </TableRow >
    )
  };

  formattedLeague = (user, picks, league) => {
    const userHasLeagueHidden = user.leagues && user.leagues.length > 0 && user.leagues.indexOf(league.toUpperCase()) === -1;
    if (picks[league].length > 0 && !userHasLeagueHidden) {
      return (
        <></>
      )
    } else {
      return <div key={league}></div>
    }
  };

  mobileTable = (picks, sort, propsite, propsites, leagues, selectedLeagues, startsAt, expandedPicks, isAdmin) => {
    /*const expandeds = Object.keys(expandedPicks).filter((key) => {
      return expandedPicks[key];
    })
    const generateEntryOpacity = (expandeds.length > 0) ? 1 : 0.5;*/
    return (
      <Table size="small" aria-label="picks table">
        <TableHead>
          <TableRow style={{}}>
            <TableCell style={{ width: "10px", border: "none", padding: "0px" }}>
            </TableCell>
            <TableCell colSpan={2} style={{ fontWeight: 600, border: "none", }} align="center">
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Propsite</InputLabel>
                <Select
                  labelId="sort-select-outlined-label"
                  id="sort-select-outlined"
                  value={propsite}
                  onChange={this.onUpdatePropsite}
                  label="Propsite"
                >
                  {propsites.map(propsite => {
                    return (
                      <MenuItem value={propsite}>{util.propsiteTitled(propsite)}</MenuItem>
                    )
                  })
                  }
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Sort</InputLabel>
                <Select
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  labelId="sort-select-outlined-label"
                  id="sort-select-outlined"
                  value={sort}
                  onChange={this.onUpdateSort}
                  label="Sort"
                >
                  <MenuItem value={"SCORE"}>Score</MenuItem>
                  <MenuItem value={"ODDS"}>Odds</MenuItem>
                  <MenuItem value={"LINE_DIFFERENCE"}>Line Difference</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Starts At</InputLabel>
                <Select
                  style={{ marginRight: "10px" }}
                  labelId="sort-select-outlined-label"
                  id="sort-select-outlined"
                  value={startsAt}
                  onChange={this.onUpdateStartsAt}
                  label="Starts At"
                >
                  <MenuItem value={"ANY"}>Any</MenuItem>
                  <MenuItem value={"TODAY"}>Today</MenuItem>
                </Select>
              </FormControl>
              <MultiSelectField
                key={"league"}
                options={leagues}
                selected={selectedLeagues}
                selectAllIfNone={true}
                name={"Leagues"}
                onClose={this.onUpdateLeagues}>
              </MultiSelectField>
            </TableCell>
          </TableRow>
          {/*propsite === "prizepicks" &&
            <TableRow>
              <TableCell style={{ width: "10px", border: "none", padding: "0px" }} colSpan={3}>
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth={true}
                  style={{ opacity: generateEntryOpacity }}
                  onClick={() => this.showExternalURL()}
                >
                  Generate Entry
                </Button>
              </TableCell>
            </TableRow>
                */}
          <TableRow>
            <TableCell style={{ width: "10px", border: "none", padding: "0px" }}>
            </TableCell>
            <TableCell style={{ fontWeight: 600, padding: "4px", paddingBottom: "12px", borderBottom: "1px solid #F08A20" }} align="left">
              Score/Player
              <br />
              Prop/Pick <span style={{ color: "gray" }}>(Diff)</span>
            </TableCell>
            <TableCell style={{ fontWeight: 600, padding: "4px", paddingBottom: "12px", borderBottom: "1px solid #F08A20" }} align="right">
              Sportsbook line/Odds
              <br />
              <span style={{ color: "gray" }}>Game/League/Time</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody >
          {picks.map((pick, i) => {
            const isExpanded = expandedPicks[pick.id] === true;
            const showSportsbook = isExpanded && isAdmin;
            const showRecentChart = isExpanded === true;
            const showOutliers = isAdmin;
            return this.mobileTableRow(pick, showOutliers, showSportsbook, showRecentChart, isExpanded);
          })}
        </TableBody>
      </Table >
    )
  };

  desktopTable = (picks, sort, propsite, propsites, leagues, selectedLeagues, startsAt, expandedPicks, isAdmin) => {
    /*const expandeds = Object.keys(expandedPicks).filter((key) => {
      return expandedPicks[key];
    })
    const generateEntryOpacity = (expandeds.length > 0) ? 1 : 0.5;*/
    return (
      <Table size="small" aria-label="picks table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 600, border: "none" }}>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Propsite</InputLabel>
                <Select
                  labelId="sort-select-outlined-label"
                  id="sort-select-outlined"
                  value={propsite}
                  onChange={this.onUpdatePropsite}
                  label="Propsite"
                >
                  {propsites.map(propsite => {
                    return (
                      <MenuItem value={propsite}>{util.propsiteTitled(propsite)}</MenuItem>
                    )
                  })
                  }
                </Select>
              </FormControl>
              {/*propsite === "prizepicks" &&
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth={true}
                  style={{ opacity: generateEntryOpacity, padding: "2px", minWidth: "0px", fontSize: "0.75rem" }}
                  onClick={() => this.showExternalURL()}
                >
                  Generate Entry
                </Button>
                */}
            </TableCell>
            <TableCell style={{ fontWeight: 600, borderBottom: "1px solid #F08A20" }}>Name</TableCell>
            <TableCell style={{ fontWeight: 600, borderBottom: "1px solid #F08A20" }} align="center">Prop</TableCell>
            <TableCell style={{ fontWeight: 600, borderBottom: "1px solid #F08A20" }} align="center">Pick</TableCell>
            <TableCell style={{ fontWeight: 600, borderBottom: "1px solid #F08A20" }} align="center">
              Score
              <br />
              <Checkbox
                color="secondary"
                checked={sort === "SCORE"}
                variant="contained"
                onClick={this.onSortScore}
              />
            </TableCell>
            <TableCell style={{ fontWeight: 600, borderBottom: "1px solid #F08A20" }} align="center">
              Sportsbooks
              <br />
              <Checkbox
                color="secondary"
                checked={sort === "ODDS"}
                variant="contained"
                onClick={this.onSortOdds}
              />
            </TableCell>
            <TableCell style={{ fontWeight: 600, borderBottom: "1px solid #F08A20" }} align="center">
              Diff
              <br />
              <Checkbox
                color="secondary"
                checked={sort === "LINE_DIFFERENCE"}
                variant="contained"
                onClick={this.onSortLineDifference}
              />
            </TableCell>
            <TableCell style={{ fontWeight: 600, borderBottom: "1px solid #F08A20" }} align="center">Game
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Starts At</InputLabel>
                <Select
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  labelId="sort-select-outlined-label"
                  id="sort-select-outlined"
                  value={startsAt}
                  onChange={this.onUpdateStartsAt}
                  label="Starts At"
                >
                  <MenuItem value={"ANY"}>Any</MenuItem>
                  <MenuItem value={"TODAY"}>Today</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell style={{ fontWeight: 600, borderBottom: "1px solid #F08A20" }} align="left">
              <MultiSelectField
                key={"league"}
                options={leagues}
                selected={selectedLeagues}
                selectAllIfNone={true}
                name={"Leagues"}
                onClose={this.onUpdateLeagues}>
              </MultiSelectField>
            </TableCell>
            <TableCell style={{ fontWeight: 600, borderBottom: "1px solid #F08A20" }} >
            </TableCell>
          </TableRow >
        </TableHead >
        <TableBody >
          {picks.map((pick, i) => {
            const isExpanded = expandedPicks[pick.id] === true;
            const showSportsbook = isExpanded && isAdmin;
            const showRecentChart = isExpanded;
            const showPublicIcon = isAdmin;
            const showOutliers = isAdmin;
            return this.desktopTableRow(pick, showSportsbook, showPublicIcon, showOutliers, showRecentChart, isExpanded)
          })}
        </TableBody>
      </Table >
    )
  }

  render() {
    const {
      ready,
      selectedPicks,
      propsite,
      leagues,
      selectedLeagues,
      sort,
      startsAt,
      expandedPicks,
      banners,
      message
    } = this.state;

    const {
      user,
      propsites,
      width,
      openSnackbar,
      onPicksTutorialClicked,
    } = this.props;

    const isUserActive = (user && user.subscription_ends_at && moment.utc(user.subscription_ends_at).local() > new Date());
    const hasContent = selectedPicks.length > 0;
    const isSmallScreen = width === "xs";
    return (
      <Box m={2} p={{ xs: 0, sm: 2 }} mx={{ xs: 0 }}>
        {!ready && <LaunchScreen />}
        <Container>
          <BannersContainer
            banners={banners}
            user={user}
            propsite={propsite}
            openSnackbar={openSnackbar}
            onPicksTutorialClicked={onPicksTutorialClicked}
          />
        </Container>
        {
          ready && user &&
          <Container>
            <Grid item xs={12}>
              <TableContainer style={{ overflowX: 'auto' }}>
                {isSmallScreen && this.mobileTable(selectedPicks, sort, propsite, propsites, leagues, selectedLeagues, startsAt, expandedPicks, user.is_admin)}
                {!isSmallScreen && this.desktopTable(selectedPicks, sort, propsite, propsites, leagues, selectedLeagues, startsAt, expandedPicks, user.is_admin)}
              </TableContainer>
            </Grid>
          </Container>
        }

        {
          ready && isUserActive && hasContent &&
          <TwitterFollowPrompt />
        }

        {
          ready && isUserActive && !hasContent &&
          < EmptyState
            image={<img src={images.imageFor("no_picks")} alt="No Picks" />}
            title="No Picks"
            description={message}
            showTwitterFollowPrompt={true}
          />
        }

        {
          ready && !isUserActive &&
          < EmptyState
            image={<img src={images.imageFor("no_subscription")} alt="Whoops" />}
            title="Whoops"
            description="You have an invalid subscription. Visit Settings to manage."
          />
        }
      </Box >
    );
  }

  getRecentPicks(pick) {
    if (this.state.recentPicks[pick.id]) {
      return;
    }

    subscription.getPick(pick.id)
      .then((data) => {
        let recentPicks = this.state.recentPicks;
        data.recent_picks = data.recent_picks.reverse();
        recentPicks[pick.id] = data;
        this.setState({
          recentPicks: recentPicks,
        }, function () {
          this.filterPicks();
        });
      })
      .catch((error) => {
        this.props.openSnackbar(error);
      })
  };

  getPicks(resetLeagues) {
    const {
      propsite
    } = this.state;
    let {
      selectedLeagues
    } = this.state;
    subscription.getPicks(propsite)
      .then((data) => {
        let picks = data.picks;
        const leagues = data.leagues;
        if (resetLeagues) {
          selectedLeagues = leagues;
        } else if (selectedLeagues.length === 0 || !selectedLeagues.every(val => leagues.includes(val))) {
          selectedLeagues = leagues;
        }
        this.setState({
          ready: true,
          picks: picks,
          selectedPicks: picks,
          leagues: leagues,
          selectedLeagues: selectedLeagues,
          message: data.message
        }, function () {
          this.filterPicks();
        });
      })
      .catch((error) => {
        this.props.openSnackbar(error);
        this.setState({
          ready: true,
          picks: []
        })
      })
  }

  componentDidMount() {
    document.title = "Picks | HammerPicks";
    googleAnalytics.logPageView(document.title);

    this.getPicks();

    this.bannersSnapshotListener = firestore.collection("banners").where("publishedAt", "<", new Date()).orderBy("publishedAt", "desc").onSnapshot((snapshot) => {
      if (!snapshot || snapshot.isEmpty) {
        return;
      }

      const data = snapshot.docs.map(doc => {
        const { unpublishedAt } = doc.data();
        if (unpublishedAt && unpublishedAt.toDate() < new Date()) {
          return null;
        } else if (doc.data().platforms && !doc.data().platforms.includes("web")) {
          return null;
        }
        return {
          id: doc.id,
          data: doc.data()
        }
      })

      this.setState({
        banners: data.filter(b => b),
      });
    }, (error) => {
      console.log(error);
    });
  }

  componentWillUnmount() {
    if (this.bannersSnapshotListener) {
      this.bannersSnapshotListener();
    }
  }
}

PicksPage.propTypes = {
  user: PropTypes.object,
  propsites: PropTypes.array.isRequired,
  openSnackbar: PropTypes.func.isRequired,
};

export default withWidth()(PicksPage);
