import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Badge
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import EmptyState from "../EmptyState";

import subscription from "../../services/subscription";
import LaunchScreen from "../LaunchScreen";

const styles = (theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  cardHeader: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[300],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  button: {
    fontWeight: 'bold'
  }
});


class CheckoutTiers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      products: [],
    };
  }

  componentDidMount() {
    subscription.getProducts()
      .then((products) => {
        this.setState({
          ready: true,
          products: products
        });
      })
      .catch((error) => {
        this.props.openSnackbar(error);
        this.setState({
          ready: true,
          products: []
        })
      })
  }

  render() {
    const { ready, products } = this.state;

    if (!ready) {
      return <LaunchScreen />
    }

    const { classes, title, description, priceFontSize, buttonAction, highlightedTiers, user, onCheckoutClicked } = this.props;
    let disabledTiers = this.props.disabledTiers || [];
    if (user && !user.subscription_cancelled_at) {
      switch (user.subscription_tier) {
        case "week":
          disabledTiers = disabledTiers.concat(["week"]);
          break;
        case "month":
          disabledTiers = disabledTiers.concat(["week", "month"]);
          break;
        case "6-month":
          disabledTiers = disabledTiers.concat(["week", "month", "6-month"]);
          break;
        case "year":
          disabledTiers = disabledTiers.concat(["week", "month", "6-month", "year"]);
          break;
        case "lifetime":
          disabledTiers = disabledTiers.concat(["week", "month", "6-month", "year"]);
          break;
        default:
          break;
      }
    }
    const enabledTiers = products.filter(tier => !disabledTiers.includes(tier.id));
    if (enabledTiers.length === 0) {
      return (
        <EmptyState
          title="No Products Available"
          description="Please contact support to purchase or modify your subscription."
        />
      );
    }
    return (
      <Box m={3}>
        <Container maxWidth="md" component="main">
          <Box p={4} align="center">
            <Typography variant="h5" style={{ fontWeight: 700 }}>
              {title}
            </Typography>
            <br />
            <Typography variant="subtitle1" style={{ color: "gray" }}>
              {description}
            </Typography>
            <br />
            <Typography variant="body1">
            </Typography>
          </Box>
          <Grid container spacing={1} alignItems="flex-end">
            {enabledTiers.map((tier) => (
              <Grid item key={tier.title} xs={12} sm={12 / enabledTiers.length}>
                <Card>
                  <CardHeader
                    title={
                      <Badge badgeContent={tier.discount > 0 ? `${tier.discount * 100}% off` : 0} color="secondary">
                        {tier.title}
                      </Badge>
                    }
                    subheader={`${tier.subheader}`}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography variant={priceFontSize || "h3"} color="textPrimary">
                        ${tier.price}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        <sup>.99</sup>/{tier.price_period}
                      </Typography>
                    </div>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography component="li" variant="subtitle1" align="center" key={line}>
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth className={classes.button}
                      variant={highlightedTiers.includes(tier.id) ? 'contained' : 'outlined'}
                      color="secondary"
                      onClick={() => onCheckoutClicked(tier.id)}>
                      {`${buttonAction || "Continue with"} ${tier.button_text}`}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    );
  }
}

CheckoutTiers.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonAction: PropTypes.string,
  priceFontSize: PropTypes.string,
  disabledTiers: PropTypes.array,
  highlightedTiers: PropTypes.array,
  onCheckoutClicked: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default withStyles(styles)(CheckoutTiers);
