const images = {};

const nfl = {
  "home": "jameis_winston.gif",
  "success": "aaron_rodgers.gif",
  "unverified": "patrick_mahomes.gif",
  "welcome_back": "odell_beckham.gif",
  "404": "tom_brady.gif",
  "no_picks": "tom_brady.gif",
  "no_subscription": "tom_brady.gif"
}

const nba = {
  "home": "giannis.gif",
  "success": "steph_curry.gif",
  "unverified": "luka_doncic.gif",
  "welcome_back": "kobe_bryant.gif",
  "404": "jr_smith.gif",
  "no_picks": "jr_smith.gif",
  "no_subscription": "lebron_james_what.gif"
}

const mlb = {
  "home": "home_run.gif",
  "success": "jose_bautista.gif",
  "unverified": "bring_him_home.gif",
  "welcome_back": "shohei_ohtani.gif",
  "404": "javier_baez.gif",
  "no_picks": "javier_baez.gif",
  "no_subscription": "randy_johnson.gif"
}

images.league = () => {
  const month = (new Date()).getMonth();
  switch (month) {
    case 2:
    case 3:
    case 4:
    case 5:
      return "nba";
    case 6:
    case 7:
    case 8:
      return "mlb";
    default:
      return "nfl";
  }
}

images.imageFor = (id) => {
  const league = images.league();
  switch (league) {
    case "nba":
      return `images/gifs/${league}/${nba[id]}`;
    case "mlb":
      return `images/gifs/${league}/${mlb[id]}`;
    case "nfl":
      return `images/gifs/${league}/${nfl[id]}`;
    default:
      return "";
  }
};

export default images;
