import React, { Component } from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import {
  Grid,
  Box,
  Paper,
  Tooltip,
  IconButton
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

const styles = (theme) => ({
  box: {
    position: "relative"
  },
  closeButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
  }
});
class BannerDialog extends Component {

  render() {
    const {
      classes,
      id,
      textColor,
      backgroundColor,
      content,
      displayedBanners,
      onCloseBanner,
    } = this.props;

    if (displayedBanners.includes(id)) {
      return <></>
    }

    return (
      <Grid item xs={12}>
        <Box p={2} mx={2}>
          <Paper style={{ color: textColor, backgroundColor: backgroundColor }}>
            <Box p={2} className={classes.box}>
              {content}
              <Tooltip title="Close">
                <IconButton
                  className={classes.closeButton}
                  onClick={onCloseBanner}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Paper>
        </Box>
      </Grid>
    );
  }
}

BannerDialog.propTypes = {
  id: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  content: PropTypes.element.isRequired,
  displayedBanners: PropTypes.array.isRequired,
  onCloseBanner: PropTypes.func.isRequired
};

export default withStyles(styles)(BannerDialog);
