import { createTheme } from "@material-ui/core/styles";


const defaultPrimaryColor = process.env.REACT_APP_THEME_PRIMARY_COLOR;
const defaultSecondaryColor = process.env.REACT_APP_THEME_SECONDARY_COLOR;
const defaultDark = "light";

const defaultTheme = createTheme({
  typography: {
    fontFamily: [
      "Lato"
    ].join(",")
  },
  palette: {
    primary: {
      main: defaultPrimaryColor
    },
    secondary: {
      main: defaultSecondaryColor
    },
    type: defaultDark
  },

  primaryColor: defaultPrimaryColor,
  secondaryColor: defaultSecondaryColor,
  dark: defaultDark,
});

const appearance = {};

appearance.defaultPrimaryColor = defaultPrimaryColor;
appearance.defaultSecondaryColor = defaultSecondaryColor;
appearance.defaultDark = defaultDark;

appearance.defaultTheme = defaultTheme;

export default appearance;
