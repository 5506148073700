import { analytics } from "../firebase";

import axios from "axios";

const stats = {};

stats.getStatsHome = () => {
  return new Promise((resolve, reject) => {
    const url = new URL(process.env.REACT_APP_API_ENDPOINT + "/stats-home");
    axios.get(url.toString())
      .then(res => {
        analytics.logEvent("get_stats_home");
        const data = res.data;
        resolve(data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

stats.getStatsUsers = (user_days_ago) => {
  return new Promise((resolve, reject) => {
    const url = new URL(process.env.REACT_APP_API_ENDPOINT + "/stats-users");
    if (user_days_ago) {
      url.searchParams.set("user_days_ago", user_days_ago);
    }
    axios.get(url.toString())
      .then(res => {
        analytics.logEvent("get_stats");
        const data = res.data;
        resolve(data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}


stats.getStatsPicks = (type, from_datetime, to_datetime, leagues, sportsbooks, propsites) => {
  return new Promise((resolve, reject) => {
    const url = new URL(process.env.REACT_APP_API_ENDPOINT + "/stats-picks");
    url.searchParams.set("type", type);
    url.searchParams.set("from_datetime", from_datetime);
    url.searchParams.set("to_datetime", to_datetime);
    if (leagues && leagues.length > 0) {
      url.searchParams.set("leagues", leagues);
    }
    if (sportsbooks && sportsbooks.length > 0) {
      url.searchParams.set("sportsbooks", sportsbooks);
    }
    if (propsites && propsites.length > 0) {
      url.searchParams.set("propsites", propsites);
    }
    axios.get(url.toString())
      .then(res => {
        analytics.logEvent("get_stats_record");
        const data = res.data;
        resolve(data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

stats.userUpdateForce = (email, subscription_cancellation_reason) => {
  return new Promise((resolve, reject) => {
    const data = {
      email: email,
      subscription_cancellation_reason: subscription_cancellation_reason
    }
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/user-update-force", data)
      .then(res => {
        analytics.logEvent("user_update_force");
        const data = res.data;
        resolve(data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

export default stats;
