import { analytics } from "../firebase";

import axios from "axios";

const authentication = {
  currentUser: null,
  didChangeAuth: null
};

const setCurrentUser = (user) => {
  authentication.currentUser = user;
  if (authentication.didChangeAuth) {
    authentication.didChangeAuth(user);
  }
};

const updateCurrentUser = (data) => {
  if (!authentication.currentUser) {
    return;
  }
  for (const key in data) {
    authentication.currentUser[key] = data[key];
  }
  if (authentication.didChangeAuth) {
    authentication.didChangeAuth(authentication.currentUser);
  }
};

authentication.getJWT = () => {
  const query = Object.fromEntries(new URLSearchParams(window.location.search));
  const localStorageJWT = localStorage.getItem('jwt');
  const queryJWT = query["jwt"]
  return localStorageJWT || queryJWT;
}

authentication.configureInterceptors = () => {
  axios.interceptors.request.use((config) => {
    config.headers.Authorization = authentication.getJWT();
    return config;
  });
  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response && error.response.status === 401) {
      setCurrentUser(null);
    }
    return Promise.reject(error);
  });
}

authentication.signUp = (firstName, lastName, email, password, promoCode, referrerEmail, source) => {
  return new Promise((resolve, reject) => {
    if (!firstName || !lastName || !email || !password) {
      reject(
        new Error(
          "No first name, last name, email, or password"
        )
      );

      return;
    }

    const data = {
      email: email,
      password: password,
      first_name: firstName,
      last_name: lastName,
      promo_code: promoCode,
      referrer_email: referrerEmail,
      source: source,
      platform: "web"
    }
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/sign-up", data)
      .then(res => {
        analytics.logEvent("sign_up", {
          method: "password",
        });
        const data = res.data;
        localStorage.setItem('jwt', data.token);
        authentication.configureInterceptors();
        setCurrentUser(data.user);
        resolve(data.user);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.signIn = (email, password) => {
  return new Promise((resolve, reject) => {
    if (!email || !password) {
      reject(new Error("No e-mail address or password"));

      return;
    }

    if (authentication.currentUser) {
      reject(new Error("Current user"));

      return;
    }
    const data = { email: email, password: password }
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/login", data)
      .then(res => {
        analytics.logEvent("login", {
          method: "password",
        });
        const data = res.data;
        localStorage.setItem('jwt', data.token);
        authentication.configureInterceptors();
        setCurrentUser(data.user);
        resolve(data.user);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.signOut = () => {
  return new Promise((resolve, reject) => {
    analytics.logEvent("sign_out");
    localStorage.setItem('jwt', null);
    authentication.configureInterceptors();
    setCurrentUser(null);
    resolve({});
  });
};

authentication.sendEmailVerificationEmail = () => {
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/send-verify-email")
      .then(res => {
        analytics.logEvent("send_verify_email");
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

authentication.resetPassword = (email) => {
  return new Promise((resolve, reject) => {
    if (!email) {
      reject(new Error("No e-mail address"));
      return;
    }

    const data = { email: email }
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/reset-password", data)
      .then(res => {
        analytics.logEvent("reset_password");
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changePassword = (password, resetPasswordToken) => {
  return new Promise((resolve, reject) => {
    if (!resetPasswordToken || !password) {
      reject(new Error("Either no password or no token"));
      return;
    }

    const data = { password: password, reset_password_token: resetPasswordToken }
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/change-password", data)
      .then(res => {
        analytics.logEvent("change_password");
        resolve(res.data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.getCurrentUser = () => {
  const jwt = authentication.getJWT();
  if (!jwt || jwt === "null") {
    setCurrentUser(null);
    return;
  }
  return new Promise((resolve, reject) => {
    const data = { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/me", data)
      .then(res => {
        const data = res.data;
        setCurrentUser(data.user);
        resolve(data.user);
      })
      .catch((reason) => {
        setCurrentUser(null);
        reject(reason);
      });
  });
};

authentication.changeTimezoneSetting = (timezone) => {
  return new Promise((resolve, reject) => {
    const data = { timezone: timezone };
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/user-update", data)
      .then(res => {
        analytics.logEvent("user_update_timezone");
        updateCurrentUser(data);
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

authentication.changeEmailUpdatesSetting = (isEnabled) => {
  return new Promise((resolve, reject) => {
    const data = { email_updates: isEnabled };
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/user-update", data)
      .then(res => {
        analytics.logEvent("user_update_email_updates");
        updateCurrentUser(data);
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changeiOSUpdatesSetting = (isEnabled) => {
  return new Promise((resolve, reject) => {
    const data = { ios_updates: isEnabled };
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/user-update", data)
      .then(res => {
        analytics.logEvent("user_update_ios_updates");
        updateCurrentUser(data);
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changeMarketingEmailUpdatesSetting = (isEnabled) => {
  return new Promise((resolve, reject) => {
    const data = { marketing_updates: isEnabled };
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/user-update", data)
      .then(res => {
        analytics.logEvent("user_update_marketing_updates_updates");
        updateCurrentUser(data);
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changeUpdatesIntervalSetting = (updatesInterval) => {
  return new Promise((resolve, reject) => {
    const data = { updates_interval: updatesInterval }
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/user-update", data)
      .then(res => {
        analytics.logEvent("user_update_updates_interval");
        updateCurrentUser(data);
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changeLeaguesSetting = (leagues) => {
  return new Promise((resolve, reject) => {
    const data = { leagues: leagues }
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/user-update", data)
      .then(res => {
        analytics.logEvent("user_update_leagues");
        updateCurrentUser(data);
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changeSportsbooksSetting = (sportsbooks) => {
  return new Promise((resolve, reject) => {
    const data = { sportsbooks: sportsbooks }
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/user-update", data)
      .then(res => {
        analytics.logEvent("user_update_sportsbooks");
        updateCurrentUser(data);
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changePropsitesSetting = (propsites) => {
  return new Promise((resolve, reject) => {
    const data = { propsites: propsites }
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/user-update", data)
      .then(res => {
        analytics.logEvent("user_update_propsites");
        updateCurrentUser(data);
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changePicksSortSetting = (picksSort) => {
  return new Promise((resolve, reject) => {
    const data = { picks_sort: picksSort }
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/user-update", data)
      .then(res => {
        analytics.logEvent("user_update_picks_sort");
        updateCurrentUser(data);
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changeEmailScoreThresholdSetting = (emailScoreThreshold) => {
  return new Promise((resolve, reject) => {
    const data = { email_score_threshold: emailScoreThreshold }
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/user-update", data)
      .then(res => {
        analytics.logEvent("user_update_email_score_threshold");
        updateCurrentUser(data);
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changeSubscriptionTier = (tier) => {
  return new Promise((resolve, reject) => {
    const data = { tier: tier }
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/user-subscription-update", data)
      .then(res => {
        analytics.logEvent("user_update_subscription_tier");
        updateCurrentUser(data);
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.addPromoToNextPayment = (promo_code) => {
  return new Promise((resolve, reject) => {
    const data = { promo_code: promo_code };
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/user-add-promo", data)
      .then(res => {
        analytics.logEvent("user_add_promo");
        updateCurrentUser(data);
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.updateEmailSettings = (email, settings) => {
  return new Promise((resolve, reject) => {
    const data = settings;
    axios.post(process.env.REACT_APP_API_ENDPOINT + `/user-update-email-settings?email=${email}`, data)
      .then(res => {
        analytics.logEvent("user_update_email_settings");
        updateCurrentUser(data);
        resolve({});
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.getNameInitials = () => {
  if (!authentication.currentUser) {
    return null;
  }

  const firstName = authentication.currentUser.first_name;
  const lastName = authentication.currentUser.last_name;

  if (firstName && lastName) {
    return firstName.charAt(0) + lastName.charAt(0);
  }
  if (firstName) {
    return firstName.charAt(0);
  }
  if (lastName) {
    return lastName.charAt(0);
  }
  return null;
};

export default authentication;
