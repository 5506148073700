import React, { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from 'react-router-dom';

import {
  Box,
} from "@material-ui/core";

import googleAnalytics from "../../services/googleAnalytics";
import LaunchScreen from "../LaunchScreen";
import AccountTab from "../AccountTab";

class SettingsPage extends Component {

  componentDidMount() {
    document.title = "Settings | HammerPicks";
    googleAnalytics.logPageView(document.title);
  }

  render() {
    const { user, leagues, sportsbooks, propsites, openSnackbar } = this.props;

    if (!user) {
      return <LaunchScreen />
    }

    return (
      <Box>
        <AccountTab
          user={user}
          leagues={leagues}
          sportsbooks={sportsbooks}
          propsites={propsites}
          openSnackbar={openSnackbar}
        />
      </Box>
    );
  }
}

SettingsPage.propTypes = {
  openSnackbar: PropTypes.func.isRequired,
  user: PropTypes.object,
  leagues: PropTypes.array.isRequired,
  sportsbooks: PropTypes.array.isRequired,
  propsites: PropTypes.array.isRequired,
};

export default withRouter(SettingsPage);
