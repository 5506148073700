import React, { Component } from "react";

import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Checkbox
} from "@material-ui/core";

const styles = (theme) => ({
  formControl: {
    minWidth: 40,
  },
});

class MultiSelectField extends Component {
  constructor(props) {
    super(props);

    let selected = props.selected || [];
    if (props.selectAllIfNone && selected.length === 0) {
      selected = props.options;
    }

    this.state = { selected: selected };
  }

  handleChange = (event) => {
    const selected = event.target.value;

    this.setState({
      selected: selected
    });
  }

  render() {
    const { classes, options, name, onClose, selectAllIfNone } = this.props;
    const { selected } = this.state;
    let onCloseSelected = selected;
    if (selectAllIfNone && selected.length === options.length) {
      onCloseSelected = [];
    }
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="multi-select-field-outlined-label" style={{ backgroundColor: "white", paddingLeft: "4px", paddingRight: "4px" }}>{name}</InputLabel>
        <Select
          labelId="multi-select-field-label"
          id="multi-select-field"
          multiple
          value={selected}
          onChange={this.handleChange}
          onClose={() => onClose(onCloseSelected)}
          renderValue={(s) =>
            s.length === 0 ? (selectAllIfNone ? "All" : "None") :
              s.length === options.length ? "All" :
                s.join(', ')
          }
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={selected.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

MultiSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default withStyles(styles)(MultiSelectField);