import React, { Component } from "react";

import { withStyles } from '@material-ui/core/styles';

import {
  Typography,
  Box,
  Link
} from "@material-ui/core";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXTwitter, faTiktok, faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'

import PropTypes from "prop-types";

import moment from "moment";

const styles = (theme) => ({
  footer: {
    backgroundColor: process.env.REACT_APP_THEME_PRIMARY_COLOR,
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    width: "100%"
  }
});

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        anchorEl: null,
      },
    };

  }

  render() {
    const {
      classes,
      articles
    } = this.props;

    const query = Object.fromEntries(new URLSearchParams(window.location.search));
    const disableFooter = query["hide"] && query["hide"].split(",").includes("footer");
    if (disableFooter) {
      return <></>
    }

    const year = moment().year();

    return (
      <footer className={classes.footer}>
        <Box bgcolor="primary" color="primary.contrastText" px={2} py={4}>
          <Typography variant="subtitle2" align="center">
            {articles.map((article, i) => {
              return (
                <span key={article.id}>
                  <Link href={`/article/${article.id}`}
                    color="inherit"
                    underline="always">
                    {article.data.title}
                  </Link>
                  {i + 1 !== articles.length && " | "}
                </span>
              )
            })}
            <br />
            <br />
            <Link href="https://apps.apple.com/us/app/hammerpicks/id1667653290">
              <img src={"/images/download_app_store.svg"} style={{}} alt="Download on App Store" />
            </Link>
            <br />
            <br />
            <Link href="https://twitter.com/hammerpicksHQ" color="inherit">
              <FontAwesomeIcon size="2xl" icon={faXTwitter} style={{ marginLeft: "20px" }} />
            </Link>
            <Link href="https://www.tiktok.com/@hammerpickshq" color="inherit">
              <FontAwesomeIcon size="2xl" icon={faTiktok} style={{ marginLeft: "20px" }} />
            </Link>
            <Link href="https://www.instagram.com/hammerpicksHQ" color="inherit">
              <FontAwesomeIcon size="2xl" icon={faInstagram} style={{ marginLeft: "20px" }} />
            </Link>
            <Link href="https://www.facebook.com/hammerpicks" color="inherit">
              <FontAwesomeIcon size="2xl" icon={faFacebook} style={{ marginLeft: "20px" }} />
            </Link>
            <Link href="https://www.youtube.com/channel/UCNf7McNkcMEoHxVdUCVUMxw" color="inherit">
              <FontAwesomeIcon size="2xl" icon={faYoutube} style={{ marginLeft: "20px" }} />
            </Link>
            <br />
            <br />
            © 2022-{year} HammerPicks LLC
            {" | "}
            <Link href="/terms"
              color="inherit"
              underline="always">
              Terms
            </Link>
            {" | "}
            <Link href="/privacy"
              color="inherit"
              underline="always">
              Privacy
            </Link>
          </Typography>
        </Box>
      </footer >
    );
  }
}

Footer.propTypes = {
  user: PropTypes.object,
  articles: PropTypes.array
};

export default withStyles(styles)(Footer);
