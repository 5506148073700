import axios from "axios";

const admin = {};

admin.addPublicPick = (pick) => {
  const data = { [pick.id]: pick };
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/admin/add-public-picks", data)
      .then(res => {
        const data = res.data;
        resolve(data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

admin.getUser = (email) => {
  const data = { email: email };
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/admin/get-user", data)
      .then(res => {
        const data = res.data;
        resolve(data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

admin.getPick = (id) => {
  const data = { id: id };
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/admin/get-pick", data)
      .then(res => {
        const data = res.data;
        resolve(data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

admin.runMigration = (migration_name, page, end_page, per_page, background) => {
  const data = {};
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_API_ENDPOINT + `/admin/run-migration?migration_name=${migration_name}&page=${page}&end_page=${end_page}&per_page=${per_page}&background=${background}`, data)
      .then(res => {
        const data = res.data;
        resolve(data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

admin.calculateScore = (params) => {
  const data = params;
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_API_ENDPOINT + "/admin/calculate-score", data)
      .then(res => {
        const data = res.data;
        resolve(data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

export default admin;
