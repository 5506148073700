import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
  IconButton,
  Button
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { Close as CloseIcon } from "@material-ui/icons";

import authentication from "../../services/authentication";

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
});

const initialState = {
  performingAction: false,
};

class RetentionPromoDialog extends Component {

  addPromo(promoCode) {
    this.setState({
      performingAction: true
    }, () => {
      authentication.addPromoToNextPayment(promoCode)
        .then(() => {
          this.props.openSnackbar("Applied promo code");
          this.props.dialogProps.onClose();
        })
        .catch((error) => {
          this.props.openSnackbar(error);
          this.setState({
            performingAction: false
          })
        })
    })
  }

  constructor(props) {
    super(props);

    this.state = initialState;
  }

  render() {
    const {
      classes,
      dialogProps,
      promoCode,
      onCustomerPortalClicked
    } = this.props;
    const { performingAction } = this.state;

    return (
      <Dialog fullWidth maxWidth="md" {...dialogProps}>
        <DialogTitle disableTypography>
          <Typography variant="h6" style={{ fontWeight: 700 }}>
            Cancel? How can you hammer props if you cancel?
          </Typography>

          <Tooltip title="Close">
            <IconButton
              className={classes.closeButton}
              onClick={dialogProps.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1">
            We'd like to offer you 20% off your next payment. But only if you stay.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={performingAction}
            variant="outlined"
            onClick={() => onCustomerPortalClicked(false)}
          >
            No, thanks. I don't like free things
          </Button>

          <Button
            color="primary"
            disabled={performingAction}
            variant="contained"
            onClick={() => this.addPromo(promoCode)}
          >
            Stay & Get 20% off
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

RetentionPromoDialog.propTypes = {
  dialogProps: PropTypes.object.isRequired,
  promoCode: PropTypes.string,
  onCustomerPortalClicked: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(RetentionPromoDialog);
