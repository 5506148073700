import React, { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from 'react-router-dom';

import validate from "validate.js";

import {
  Box,
  Card,
  DialogContent,
  DialogActions,
  Hidden,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";

import authentication from "../../services/authentication";
import constraints from "../../data/constraints";
import googleAnalytics from "../../services/googleAnalytics";

const initialState = {
  performingAction: false,
  password: "",
  errors: null,
};

class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  changePassword = () => {
    const { password } = this.state;
    const query = Object.fromEntries(new URLSearchParams(window.location.search));
    const resetPasswordToken = query["reset_password_token"];

    const errors = validate(
      {
        password: password,
      },
      {
        password: constraints.password,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          performingAction: true,
        },
        () => {
          authentication
            .changePassword(password, resetPasswordToken)
            .then((value) => {
              this.props.openSnackbar(`Password successfully changed`);
              this.props.history.push(value.redirect || "/");
            })
            .catch((error) => {
              this.props.openSnackbar(error);
            })
            .finally(() => {
              this.setState({
                performingAction: false,
              });
            });
        }
      );
    }
  };

  handleKeyPress = (event) => {
    const { password } = this.state;

    if (!password) {
      return;
    }

    const key = event.key;
    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === "Enter") {
      if (password) {
        this.changePassword();
      }
    }
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;

    this.setState({
      password: password,
    });
  };

  componentDidMount() {
    document.title = "Change Password | HammerPicks";
    googleAnalytics.logPageView(document.title);
  }

  render() {
    const { performingAction, password, errors } = this.state;

    return (
      <Box p={10}>
        <Card>
          <DialogContent>
            <Hidden xsDown>
              <Grid container direction="row">

                <Grid item xs={12}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs>
                      <TextField
                        autoComplete="current-password"
                        disabled={performingAction}
                        error={!!(errors && errors.password)}
                        fullWidth
                        helperText={
                          errors && errors.password ? errors.password[0] : ""
                        }
                        label="Password"
                        placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                        required
                        type="password"
                        value={password}
                        variant="outlined"
                        InputLabelProps={{ required: false }}
                        onChange={this.handlePasswordChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>

            <Hidden smUp>

              <Grid container direction="column" spacing={2}>
                <Grid item xs>
                  <TextField
                    autoComplete="current-password"
                    disabled={performingAction}
                    error={!!(errors && errors.password)}
                    fullWidth
                    helperText={
                      errors && errors.password ? errors.password[0] : ""
                    }
                    label="Password"
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    required
                    type="password"
                    value={password}
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    onChange={this.handlePasswordChange}
                  />
                </Grid>
              </Grid>
            </Hidden>
          </DialogContent>

          <DialogActions>
            <Button
              color="primary"
              disabled={performingAction}
              variant="outlined"
              onClick={this.changePassword}
            >
              Change password
            </Button>
          </DialogActions>
        </Card>
      </Box>
    );
  }
}

ChangePasswordPage.propTypes = {
  openSnackbar: PropTypes.func.isRequired,
};

export default withRouter(ChangePasswordPage);
